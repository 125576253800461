import {
  createDevice,
  getDevices,
  deleteDevice,
  listAllDevices,
  deleteDevices,
} from '@/services/api/device/device'

export default {
  namespaced: true,
  state: {
    allDevices: [],
    devices: [],
    allDevicesLoading: false,
    devicesLoading: false,
    uploadDevices: [],
  },
  mutations: {
    SET_ALL_DEVICES(state, devices) {
      state.allDevices = devices
    },
    SET_ALL_DEVICES_LOADING(state, loading) {
      state.allDevicesLoading = loading
    },
    SET_DEVICES(state, devices) {
      state.devices = devices
    },
    SET_UPLOAD_DEVICES(state, devices) {
      state.uploadDevices = devices
    },
    SET_LOADING(state, loading) {
      state.devicesLoading = loading
    },
  },
  actions: {
    async getDevices({ commit }, payload) {
      commit('SET_ALL_DEVICES_LOADING', true)
      return getDevices(payload)
        .then((res) => {
          commit('SET_DEVICES', res.data.devices)
          return res
        })
        .catch((error) => {
          console.error(error)
          commit(
            'notification/NEW',
            {
              type: 'error',
              message: 'Fetch Error',
              description: 'Faild to get devices data',
            },
            { root: true },
          )
        })
        .finally(() => commit('SET_ALL_DEVICES_LOADING', false))
    },
    async listAllDevices({ commit }) {
      commit('SET_LOADING', true)
      return listAllDevices()
        .then((res) => {
          commit('SET_ALL_DEVICES', res.data)
          return res
        })
        .catch((error) => {
          console.error(error)
          commit(
            'notification/NEW',
            {
              type: 'error',
              message: 'Fetch Error',
              description: 'Faild to get all devices data',
            },
            { root: true },
          )
        })
        .finally(() => commit('SET_LOADING', false))
    },
    async createDevice({ commit }, payload) {
      return createDevice(payload)
        .then((device) => {
          if (!device) return false
          commit(
            'notification/NEW',
            {
              type: 'success',
              message: 'Added a device',
              description: 'You have successfully added a new device',
            },
            { root: true },
          )
          return device
        })
        .catch((error) => {
          console.error(error) // Log the full error for debugging
          let errorMessage = 'Unknown error occurred' // Default error message

          if (error.response) {
            if (error.response.status === 404) {
              errorMessage = 'The requested resource was not found.'
            } else {
              errorMessage = error.response.data.message || errorMessage
            }
          }
          commit(
            'notification/NEW',
            {
              type: 'warning',
              message: 'Create Error',
              description: Array.isArray(errorMessage)
                ? errorMessage.join('\n')
                : errorMessage,
            },
            { root: true },
          )
        })
    },
    async deleteDevice({ commit }, payload) {
      return deleteDevice(payload)
        .then((device) => {
          commit(
            'notification/NEW',
            {
              type: 'success',
              message: 'Device deleted',
              description: 'You have successfully deleted a device',
            },
            { root: true },
          )
          return device
        })
        .catch((error) => {
          console.error(error)
          commit(
            'notification/NEW',
            {
              type: 'error',
              message: 'Delete Error',
              description: 'Faild to delete a device',
            },
            { root: true },
          )
        })
    },
    async deleteDevices({ commit }, payload) {
      return deleteDevices(payload)
        .then((device) => {
          commit(
            'notification/NEW',
            {
              type: 'success',
              message: 'Devices deleted',
              description: 'You have successfully deleted a devices',
            },
            { root: true },
          )
          return vehicle
        })
        .catch((error) => {
          console.error(error)
          commit(
            'notification/NEW',
            {
              type: 'error',
              message: 'Delete Error',
              description: 'Faild to delete a devices',
            },
            { root: true },
          )
        })
    },
    async setUploadDevices({ commit }, payload) {
      commit('SET_UPLOAD_DEVICES', payload)
    },
  },
  getters: {},
}
