export const mapboxAccessToken =
  'pk.eyJ1IjoidGhlZGlsaWdlbnQiLCJhIjoiY2todTJvdDBvMHBrdDJybWFleHlhZ25jbSJ9.BaK4xxxbEb-piX9GUaz8dQ'
export const maxVehicleAgeOptions = [
  { label: '20 Years', value: '20' },
  { label: '19 Years', value: '19' },
  { label: '18 Years', value: '18' },
  { label: '17 Years', value: '17' },
  { label: '16 Years', value: '16' },
  { label: '15 Years', value: '15' },
  { label: '14 Years', value: '14' },
  { label: '13 Years', value: '13' },
  { label: '12 Years', value: '12' },
  { label: '11 Years', value: '11' },
  { label: '10 Years', value: '10' },
  { label: '9 Years', value: '9' },
  { label: '8 Years', value: '8' },
  { label: '7 Years', value: '7' },
  { label: '6 Years', value: '6' },
  { label: '5 Years', value: '5' },
  { label: '4 Years', value: '4' },
  { label: '3 Years', value: '3' },
  { label: '2 Years', value: '2' },
  { label: '1 Years', value: '1' },
]

export const gradeOptions = [
  { value: 'pk', label: 'PK' },
  { value: 'k', label: 'K' },
  { value: 1, label: '1st' },
  { value: 2, label: '2nd' },
  { value: 3, label: '3rd' },
  { value: 4, label: '4th' },
  { value: 5, label: '5th' },
  { value: 6, label: '6th' },
  { value: 7, label: '7th' },
  { value: 8, label: '8th' },
  { value: 9, label: '9th' },
  { value: 10, label: '10th' },
  { value: 11, label: '11th' },
  { value: 12, label: '12th' },
  { value: 13, label: '12+' },
]

export const maxRouteLengthTimeOptions = [
  { label: 'Time', value: 0 },
  {
    label: '15 mins',
    value: 15,
  },
  {
    label: '20 mins',
    value: 20,
  },
  {
    label: '25 mins',
    value: 25,
  },
  {
    label: '30 mins',
    value: 30,
  },
  {
    label: '35 mins',
    value: 35,
  },
  {
    label: '40 mins',
    value: 40,
  },
  {
    label: '45 mins',
    value: 45,
  },
  {
    label: '50 mins',
    value: 50,
  },
  {
    label: '55 mins',
    value: 55,
  },
  {
    label: '60 mins',
    value: 60,
  },
  {
    label: '90 mins',
    value: 90,
  },
]

export const stopDistanceFeetOptions = [
  { label: 'Feet', value: 0 },
  { label: 'Home (Address)', value: 1 },
  { label: '500 Feet', value: 500 },
  { label: '600 Feet', value: 600 },
  { label: '700 Feet', value: 700 },
  { label: '800 Feet', value: 800 },
  { label: '900 Feet', value: 900 },
  { label: '1000 Feet', value: 1000 },
  { label: '1100 Feet', value: 1100 },
  { label: '1200 Feet', value: 1200 },
  { label: '1300 Feet', value: 1300 },
  { label: '1400 Feet', value: 1400 },
  { label: '1500 Feet', value: 1500 },
  { label: '1600 Feet', value: 1600 },
  { label: '1700 Feet', value: 1700 },
  { label: '1800 Feet', value: 1800 },
  { label: '1900 Feet', value: 1900 },
  { label: '2000 Feet', value: 2000 },
]

export const PLACES_URL = 'https://api.mapbox.com/geocoding/v5/mapbox.places'
export const DRIVING_URL = 'https://api.mapbox.com/directions/v5/mapbox/driving-traffic'
export const WALKING_URL = 'https://api.mapbox.com/directions/v5/mapbox/walking'
export const OPTIMIZATION_URL = 'https://api.mapbox.com/optimized-trips/v1/mapbox/driving-traffic'

export const EMPLOYEE_RELATION_OPTIONS = [
  { label: 'Parent', value: 'Parent' },
  { label: 'Guardian', value: 'Guardian' },
  { label: 'Sibling', value: 'Sibling' },
  { label: 'Relative', value: 'Relative' },
  { label: 'Child', value: 'Child' },
  { label: 'Friend', value: 'Friend' },
  { label: 'Spouse', value: 'Spouse' },
  { label: 'Partner', value: 'Partner' },
  { label: 'Roommate', value: 'Roommate' },
]

export const STUDENT_REQUIREMENT_OPTIONS = [
  { label: 'Air Conditioning', value: 'airConditioning' },
  { label: 'Assigned Matron - 1', value: 'assignedMatron1' },
  { label: 'Assigned Matron - 2', value: 'assignedMatron2' },
  { label: 'Assigned Matron - 3', value: 'assignedMatron3' },
  { label: 'Booster Seat', value: 'boosterSeat' },
  { label: 'Buckle Guard', value: 'buckleGuard' },
  { label: 'Car Seat', value: 'carSeat' },
  { label: 'EpiPen', value: 'epiPen' },
  { label: 'Five Point Harness', value: 'fivePointHarness' },
  { label: 'Harness', value: 'harness' },
  { label: 'Houdini Harness', value: 'houdiniHarness' },
  { label: 'Independant Aid', value: 'independantAid' },
  { label: 'Nurse', value: 'nurse' },
  { label: 'Star Seat', value: 'starSeat' },
  { label: 'Seizure Prone', value: 'seizureProne' },
  { label: 'Wheelchair', value: 'wheelchair' },
  { label: 'Window Cushion', value: 'windowCushion' },
  { label: 'Zipper Harness', value: 'zipperHarness' },
]
