import apiClient from '@/services/axios'
import { listAllOrganizations } from '../organization/organization'
import { listAllCompanies } from '../company/company'
import {
  EMPLOYEE_ROLE_OPTIONS,
  EMPLOYEE_TYPE_OPTIONS,
  REGISTERED_STATE,
  VEHICLE_LICENSE_PERMITTED_OPTIONS,
} from '@/views/tools/routing/Components/RoutingSwitch/Controllers/Constants'
import { listAllDepots } from '../depot/depot'
import Vue from 'vue'
export function createEmployee(payload) {
  return apiClient.post('/employees', payload)
}

export function getEmployees(filter) {
  return apiClient.post('/employees/searchEmployees', filter)
}

export function listAllEmployees() {
  return apiClient.get('/employees')
}

// export async function getEmployees(query) {
//   return apiClient.get('/employees', {params: query})
// }

export async function getEmployeeById(id) {
  try {
    const response = await apiClient.get(`/employees/${id}`)
    if (response.data) {
      return {
        success: true,
        status: 200,
        message: 'Employee data',
        data: response.data,
      }
    } else {
      return {
        success: false,
        status: 404,
        data: {},
        message: `Employee with id: ${id} not found!`,
      }
    }
  } catch (error) {
    return {
      success: false,
      status: 500,
      message: 'Error fetching employee by ID',
      error,
    }
  }
}

export function updateEmployee(id, employeeData) {
  return apiClient.patch(`/employees/${id}`, employeeData)
}

export async function deleteEmployee(id) {
  return apiClient.delete(`/employees/${id}`)
}

export function deleteEmployees(ids) {
  return apiClient.delete('/employees', { data: { ids } })
}

export async function uploadExistingEmployeeData(data) {
  const organizationRes = await listAllOrganizations()
  const allOrganizations = organizationRes.data
  const companyRes = await listAllCompanies()
  const allCompanies = companyRes.data
  const employeeRoleOptions = EMPLOYEE_ROLE_OPTIONS.map((item) => item.value)
  const depotsRes = await listAllDepots()
  const allDepots = depotsRes?.data?.data
  const stateShortArray = REGISTERED_STATE.map((item) => item.value)
  const stateFullArray = REGISTERED_STATE.map((item) => item.state)
  const classArray = VEHICLE_LICENSE_PERMITTED_OPTIONS.map((item) => item.value)
  const typeArray = EMPLOYEE_TYPE_OPTIONS.map((item) => item.value)

  for (let i = 0; i < data.length; i++) {
    if (!data[i].firstName) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} firstName is empty`,
      })
      return
    }
    if (!data[i].lastName) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} lastName is empty`,
      })
      return
    }
    if (!data[i].gender) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} gender is empty`,
      })
      return
    }
    if (!data[i].birthDay) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} birthDay is empty`,
      })
      return
    }
    if (!data[i].address) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} address is empty`,
      })
      return
    }
    if (!data[i].city) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} city is empty`,
      })
      return
    }
    if (!data[i].state) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} state is empty`,
      })
      return
    }
    if (!data[i].zip) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} zip is empty`,
      })
      return
    }
    if (!data[i].phone) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} phone is empty`,
      })
      return
    }
    if (!data[i].email) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} email is empty`,
      })
      return
    }
    if (!data[i].organization) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} organization is empty`,
      })
      return
    }
    if (!data[i].company) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} company is empty`,
      })
      return
    }
    if (!data[i].employeeID?.toString()) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} employeeID is empty`,
      })
      return
    }
    if (!data[i].originalHireDate?.toString()) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} originalHireDate is empty`,
      })
      return
    }
    if (!data[i].primaryRole?.toString()) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} primaryRole is empty`,
      })
      return
    }
    if (!data[i].assignedFacility?.toString()) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} assignedFacility is empty`,
      })
      return
    }
    if (!data[i].licenseState?.toString()) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} licenseState is empty`,
      })
      return
    }
    if (!data[i].licenseType?.toString()) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} licenseType is empty`,
      })
      return
    }
    if (!data[i].licenseClass?.toString()) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} licenseClass is empty`,
      })
      return
    }
    if (
      data[i].hEndorsement?.trim().toLowerCase() !== 'yes' &&
      data[i].nEndorsement?.trim().toLowerCase() !== 'yes' &&
      data[i].pEndorsement?.trim().toLowerCase() !== 'yes' &&
      data[i].sEndorsement?.trim().toLowerCase() !== 'yes' &&
      data[i].tEndorsement?.trim().toLowerCase() !== 'yes' &&
      data[i].xEndorsement?.trim().toLowerCase() !== 'yes' &&
      data[i].mEndorsement?.trim().toLowerCase() !== 'yes' &&
      data[i].wEndorsement?.trim().toLowerCase() !== 'yes' &&
      data[i].fEndorsement?.trim().toLowerCase() !== 'yes' &&
      data[i].kEndorsement?.trim().toLowerCase() !== 'yes'
    ) {
      Vue.prototype.$notification.warning({
        message: `Row ${
          i + 1
        } At least one should be Yes in Additional Endorsement`,
      })
      return
    }
    if (
      data[i].aRestriction?.trim().toLowerCase() !== 'yes' &&
      data[i].kRestriction?.trim().toLowerCase() !== 'yes' &&
      data[i].lRestriction?.trim().toLowerCase() !== 'yes' &&
      data[i].eRestriction?.trim().toLowerCase() !== 'yes' &&
      data[i].gRestriction?.trim().toLowerCase() !== 'yes' &&
      data[i].oRestriction?.trim().toLowerCase() !== 'yes' &&
      data[i].kRestriction?.trim().toLowerCase() !== 'yes' &&
      data[i].mRestriction?.trim().toLowerCase() !== 'yes' &&
      data[i].nRestriction?.trim().toLowerCase() !== 'yes' &&
      data[i].pRestriction?.trim().toLowerCase() !== 'yes' &&
      data[i].xRestriction?.trim().toLowerCase() !== 'yes' &&
      data[i].uRestriction?.trim().toLowerCase() !== 'yes' &&
      data[i].zRestriction?.trim().toLowerCase() !== 'yes' &&
      data[i].vRestriction?.trim().toLowerCase() !== 'yes' &&
      data[i].j75Restriction?.trim().toLowerCase() !== 'yes' &&
      data[i].iRestriction?.trim().toLowerCase() !== 'yes' &&
      data[i].j90Restriction?.trim().toLowerCase() !== 'yes'
    ) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} At least one should be Yes in Restrictions`,
      })
      return
    }
    const element = data[i]

    // const employee = allEmployees.filter(
    //   (employee) =>
    //     employee.employeeId === element.employeeID.toString().trim(),
    // )
    // if (employee.length > 0) {
    //   Vue.prototype.$notification.warning({
    //     message: `Row ${i + 1} Employee ID is already exist`,
    //   })
    //   return
    // }
    if (!['male', 'female', 'other'].includes(element.gender.toLowerCase())) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} Gender is not correct`,
      })
      return
    }
    const organization = allOrganizations.find((organization) => {
      return organization.legalName === element.organization.trim()
    })
    if (!organization) {
      Vue.prototype.$notification.warning({
        message: 'Organization error: ' + element.organization,
        description:
          "This organization hasn't uploaded . Organization should be uploaded first!",
      })
      return
    }

    const company = allCompanies.find((company) => {
      return company.legalName === element.company.trim()
    })
    if (!company) {
      Vue.prototype.$notification.warning({
        message: 'Company error: ' + element.company,
        description:
          "This company hasn't uploaded . Company should be uploaded first!",
      })
      return
    }
    if (!employeeRoleOptions.includes(element.primaryRole)) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} Primary Role is not correct`,
      })
      return
    }
    const depot = allDepots.find((depot) => {
      return depot.depotName === element.assignedFacility.trim()
    })
    if (!depot) {
      Vue.prototype.$notification.warning({
        message: 'Depot error: ' + element.depot,
        description:
          "This depot hasn't uploaded . Depot should be uploaded first!",
      })
      return
    }

    if (
      !(
        stateFullArray.includes(element.licenseState) ||
        stateShortArray.includes(element.licenseState)
      )
    ) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} Registered State is not correct`,
      })
      return
    }

    if (!typeArray.includes(element.licenseType)) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} License Type is not correct`,
      })
      return
    }

    if (!classArray.includes(element.licenseClass)) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} License Class is not correct`,
      })
      return
    }
  }

  try {
    let failed = []
    let success = []
    let exists = []
    let response
    for (let e = 0; e < data.length; e++) {
      const element = data[e]
      const endorsementArray = []
      element.hEndorsement?.trim().toLowerCase() === 'yes' &&
        endorsementArray.push(1)
      element.nEndorsement?.trim().toLowerCase() === 'yes' &&
        endorsementArray.push(2)
      element.pEndorsement?.trim().toLowerCase() === 'yes' &&
        endorsementArray.push(3)
      element.sEndorsement?.trim().toLowerCase() === 'yes' &&
        endorsementArray.push(4)
      element.tEndorsement?.trim().toLowerCase() === 'yes' &&
        endorsementArray.push(5)
      element.xEndorsement?.trim().toLowerCase() === 'yes' &&
        endorsementArray.push(6)
      element.mEndorsement?.trim().toLowerCase() === 'yes' &&
        endorsementArray.push(7)
      element.wEndorsement?.trim().toLowerCase() === 'yes' &&
        endorsementArray.push(8)
      element.fEndorsement?.trim().toLowerCase() === 'yes' &&
        endorsementArray.push(9)
      element.kEndorsement?.trim().toLowerCase() === 'yes' &&
        endorsementArray.push(10)

      const restrictionArray = []
      element.aRestriction?.trim().toLowerCase() === 'yes' &&
        restrictionArray.push(1)
      element.kRestriction?.trim().toLowerCase() === 'yes' &&
        restrictionArray.push(2)
      element.lRestriction?.trim().toLowerCase() === 'yes' &&
        restrictionArray.push(3)
      element.eRestriction?.trim().toLowerCase() === 'yes' &&
        restrictionArray.push(4)
      element.gRestriction?.trim().toLowerCase() === 'yes' &&
        restrictionArray.push(5)
      element.oRestriction?.trim().toLowerCase() === 'yes' &&
        restrictionArray.push(6)
      element.kRestriction?.trim().toLowerCase() === 'yes' &&
        restrictionArray.push(7)
      element.mRestriction?.trim().toLowerCase() === 'yes' &&
        restrictionArray.push(8)
      element.nRestriction?.trim().toLowerCase() === 'yes' &&
        restrictionArray.push(9)
      element.pRestriction?.trim().toLowerCase() === 'yes' &&
        restrictionArray.push(10)
      element.xRestriction?.trim().toLowerCase() === 'yes' &&
        restrictionArray.push(11)
      element.uRestriction?.trim().toLowerCase() === 'yes' &&
        restrictionArray.push(12)
      element.zRestriction?.trim().toLowerCase() === 'yes' &&
        restrictionArray.push(13)
      element.vRestriction?.trim().toLowerCase() === 'yes' &&
        restrictionArray.push(14)
      element.j75Restriction?.trim().toLowerCase() === 'yes' &&
        restrictionArray.push(15)
      element.iRestriction?.trim().toLowerCase() === 'yes' &&
        restrictionArray.push(16)
      element.j90Restriction?.trim().toLowerCase() === 'yes' &&
        restrictionArray.push(17)

      const organization = allOrganizations.find((organization) => {
        return organization.legalName === element.organization.trim()
      })

      const company = allCompanies.find((company) => {
        return company.legalName === element.company.trim()
      })

      const eData = {
        firstName: capitalizeFirstLetter(element.firstName.trim()),
        lastName: capitalizeFirstLetter(element.lastName.trim()),
        middleName: element.middleName?.trim(),
        gender: element.gender ? element.gender.trim().toLowerCase() : 'other',
        birthDay: convertDateString(element.birthDay),
        address: element.address.trim(),
        phone: element.phone.trim(),
        email: element.email.trim(),
        organizationId: organization?.id,
        companyId: company?.id,
        employeeId: element.employeeID,
        originalHireDate: convertStringDate(element.originalHireDate),
        primaryRole: element.primaryRole,
        secondaryRole: element.secondaryRole?.trim(),
        assignedFacility: element.assignedFacility.trim(),
        companyPhone: element.companyPhone?.trim(),
        companyEmail: element.companyEmail?.trim(),
        state: element.licenseState?.trim(),
        type: element.licenseType?.trim(),
        class: element.licenseClass?.trim(),
        licenseNumber: element.licenseNumber?.trim(),
        documentNumber: element.licenseDocumentNumber?.trim(),
        issuanceDate: element.licenseIssuanceDate?.trim(),
        expirationDate: element.licenseExpirationDate?.trim(),
        additionalEndorsements: endorsementArray.join(','),
        restrictions: restrictionArray.join(','),
        notes: element.notes,
      }
      if (element.employeeID) {
        const response = await createEmployee(eData)
        if (response) {
          success.push(element)
        }
        if (!response) {
          failed.push(element)
          console.error(response.message)
        }
      }

      if (e === data.length - 1) {
        return (response = {
          status: 200,
          message: 'Emplyee data uploaded successfully',
          failed,
          success,
          exists,
        })
      }
    }
  } catch (error) {
    return {
      status: 500,
      message: ':::: UploadExistingEmployeeData :::',
      error,
    }
  }
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

function convertDateString(numericalDate) {
  if (!numericalDate) return null

  if (typeof numericalDate === 'number') {
    // Convert numerical date to milliseconds since December 30, 1899 (UTC)
    const millisecondsSince1899 = numericalDate * 24 * 60 * 60 * 1000

    // Subtract 70 years and 1 day in milliseconds
    const millisecondsSince1970 =
      millisecondsSince1899 - (70 * 365 + 18) * 24 * 60 * 60 * 1000

    // Create a Date object using the adjusted milliseconds value
    const dateObject = new Date(millisecondsSince1970)

    // Get the components of the date (month, day, year)
    const month = dateObject.getMonth() + 1 // Month is zero-indexed
    const day = dateObject.getDate()
    const year = dateObject.getFullYear()

    // Format the date components into the desired string format "MM/DD/YYYY"
    const dateString = `${year}-${month}-${day}`

    return dateString
  }

  if (typeof numericalDate === 'string') {
    return numericalDate.trim()
  } else {
    return null
  }
}

function convertStringDate(numericalDate) {
  if (!numericalDate) return null

  if (typeof numericalDate === 'number') {
      // Convert numerical date to milliseconds since December 30, 1899 (UTC)
      const millisecondsSince1899 = numericalDate * 24 * 60 * 60 * 1000

      // Subtract 70 years and 1 day in milliseconds
      const millisecondsSince1970 =
          millisecondsSince1899 - (70 * 365 + 18) * 24 * 60 * 60 * 1000

      // Create a Date object using the adjusted milliseconds value
      const dateObject = new Date(millisecondsSince1970)

      return dateObject
  }

  if (typeof numericalDate === 'string') {
      return new Date(numericalDate.trim())
  } else {
      return null
  }
}
