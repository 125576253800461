import {
  createRoutingPackage,
  listAllRoutingPackage,
} from '@/services/api/routing-package/routing-package'

export default {
  namespaced: true,
  state: {
    allRoutingPackages: [],
    routingPackages: [],
    allRoutingPackagesLoading: false,
    routingPackagesLoading: false,
  },
  mutations: {
    SET_ALL_ROUTING_PACKAGES(state, routingPackages) {
      state.allRoutingPackages = routingPackages
    },
    SET_ALL_ROUTING_PACKAGES_LOADING(state, loading) {
      state.allRoutingPackagesLoading = loading
    },
    SET_ROUTING_PACKAGES(state, routingPackages) {
      state.routingPackages = routingPackages
    },
    SET_LOADING(state, loading) {
      state.routingPackagesLoading = loading
    },
  },
  actions: {
    async getRoutingPackages({ commit }, payload) {
      commit('SET_LOADING', true)
      return listAllRoutingPackage()
        .then((routingPackages) => {
          commit('SET_ROUTING_PACKAGES', routingPackages.data)
        })
        .catch((error) => {
          console.error(error)
          commit(
            'notification/NEW',
            {
              type: 'error',
              message: 'Fetch Error',
              description: 'Faild to get routingPackages data',
            },
            { root: true },
          )
        })
        .finally(() => commit('SET_LOADING', false))
    },
    // async updateRoutingPackage({ commit }, payload) {
    //   const { id, data } = payload
    //   return updateRoutingPackage(id, data)
    //     .then((routingPackage) => {
    //       commit(
    //         'notification/NEW',
    //         {
    //           type: 'success',
    //           message: 'RoutingPackage updated',
    //           description: 'You have successfully updated the routingPackage',
    //         },
    //         { root: true },
    //       )
    //       return routingPackage
    //     })
    //     .catch((error) => {
    //       const errorMessage = error.response.data.message
    //       commit(
    //         'notification/NEW',
    //         {
    //           type: 'warning',
    //           message: 'Create Error',
    //           description: errorMessage.join('\n'),
    //         },
    //         { root: true },
    //       )
    //     })
    // },

    async createRoutingPackage({ commit }, payload) {
      return createRoutingPackage(payload)
        .then((routingPackage) => {
          commit(
            'notification/NEW',
            {
              type: 'success',
              message: 'Added a routingPackage',
              description: 'You have successfully added a new routingPackage',
            },
            { root: true },
          )
          return routingPackage
        })
        .catch((error) => {
          const errorMessage = error.response.data.message
          commit(
            'notification/NEW',
            {
              type: 'warning',
              message: 'Create Error',
              description: errorMessage.join('\n'),
            },
            { root: true },
          )
        })
    },
    // async deleteRoutingPackage({ commit }, payload) {
    //   return deleteRoutingPackage(payload.id)
    //     .then((routingPackage) => {
    //       commit(
    //         'notification/NEW',
    //         {
    //           type: 'success',
    //           message: 'RoutingPackage deleted',
    //           description: 'You have successfully deleted a routingPackage',
    //         },
    //         { root: true },
    //       )
    //       return routingPackage
    //     })
    //     .catch((error) => {
    //       console.error(error)
    //       commit(
    //         'notification/NEW',
    //         {
    //           type: 'warning',
    //           message: 'Delete Error',
    //           description: 'Faild to delete a routingPackage',
    //         },
    //         { root: true },
    //       )
    //     })
    // },
  },
  getters: {},
}
