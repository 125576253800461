const motorCoachClasses = [
  'Charter Coach - Standard',
  'Charter Coach - Luxury',
  'Charter Coach - Executive',
  'Charter Coach - Double Decker Standard',
  'Charter Coach - Double Decker Luxury',
  'Charter Coach - Double Decker Executive',
  'Commuter Coach - Standard',
  'Commuter Coach - Luxury',
  'Commuter Coach - Executive',
  'Commuter Coach - Double Decker Standard',
  'Commuter Coach - Double Decker Luxury',
  'Commuter Coach - Double Decker Executive',
  'Mini Coach - Standard',
  'Mini Coach - Luxury',
  'Mini Coach - Executive',
  'Mini Coach - Double Decker Standard',
  'Mini Coach - Double Decker Luxury',
  'Mini Coach - Double Decker Executive',
  'Residential Coach - Standard',
  'Residential Coach - Luxury',
  'Residential Coach - Executive',
  'Residential Coach - Double Decker Standard',
  'Residential Coach - Double Decker Luxury',
  'Residential Coach - Double Decker Executive',
  'Residential Mini Coach - Standard',
  'Residential Mini Coach - Luxury',
  'Residential Mini Coach - Executive',
  'Residential Mini Coach - Double Decker Standard',
  'Residential Mini Coach - Double Decker Luxury',
  'Residential Mini Coach - Double Decker Executive',
  'Specialized Coach - Standard',
  'Specialized Coach - Luxury',
  'Specialized Coach - Executive',
  'Specialized Coach - Double Decker Standard',
  'Specialized Coach - Double Decker Luxury',
  'Specialized Coach - Double Decker Executive',
  'Tour Bus - Standard',
  'Tour Bus - Luxury',
  'Tour Bus - Executive',
  'Tour Bus - Double Decker Standard',
  'Tour Bus - Double Decker Luxury',
  'Tour Bus - Double Decker Executive',
  'Transit Bus - Standard',
  'Transit Bus - Articulated',
  'Transit Bus - Double Articulated',
  'Transit Bus - Double Decker',
  'Transit Bus - Double Decker & Articulated',
  'Transit Bus - Double Decker & Double Articulated',
  'Trolley Bus - Standard',
  'Trolley Bus - Double Decker',
]

export const SHIFT_OPTIONS = [
  {
    value: 'AM',
    label: 'AM',
  },
  {
    value: 'MID',
    label: 'MID',
  },
  {
    value: 'PM',
    label: 'PM',
  },
  {
    value: 'Late 1',
    label: 'Late 1',
  },
  {
    value: 'Late 2',
    label: 'Late 2',
  },
  {
    value: 'Custom',
    label: 'Custom',
  },
]
export const EDUCATION_OPTIONS = [
  {
    value: 'Regular',
    label: 'Regular',
  },
  {
    value: 'Special',
    label: 'Special',
  },
  {
    value: 'Private',
    label: 'Private',
  },
  {
    value: 'Parochial',
    label: 'Parochial',
  },
]
export const GRADES_OPTIONS = [
  {
    value: 'PK',
    label: 'PK',
  },
  {
    value: 'K',
    label: 'K',
  },
  {
    value: '1',
    label: '1',
  },
  {
    value: '2',
    label: '2',
  },
  {
    value: '3',
    label: '3',
  },
  {
    value: '4',
    label: '4',
  },
  {
    value: '5',
    label: '5',
  },
  {
    value: '6',
    label: '6',
  },
  {
    value: '7',
    label: '7',
  },
  {
    value: '8',
    label: '8',
  },
  {
    value: '9',
    label: '9',
  },
  {
    value: '10',
    label: '10',
  },
  {
    value: '11',
    label: '11',
  },
  {
    value: '12',
    label: '12',
  },
]

export const VEHICLE_OPTIONS = {
  Car: [
    {
      type: 'Acura',
      body: ['ILX'],
      class: ['Compact'],
    },
    {
      type: 'Audi',
      body: ['A1', 'A3', 'A4', 'A5', 'A6', 'A7', 'A8'],
      class: ['Subcompact', 'Compact', 'Mid Size', 'Full Size'],
    },
    {
      type: 'BMW',
      body: ['2 Series', '3 Series', '4 Series', '5 Series', '7 Series'],
      class: ['Compact', 'Mid Size', 'Full Size'],
    },
    {
      type: 'Buick',
      body: ['LaCrosse', 'Regal', 'Verano'],
      class: ['Compact', 'Mid Size', 'Full Size'],
    },
    {
      type: 'Cadillac',
      body: ['ATS', 'CTS', 'CT6', 'XTS'],
      class: ['Compact', 'Mid Size', 'Full Size'],
    },
    {
      type: 'Chevrolet',
      body: ['Cruze', 'Impala', 'Malibu', 'Sonic', 'Spark'],
      class: ['Subcompact', 'Compact', 'Mid Size'],
    },
    {
      type: 'Chrysler',
      body: ['200', '300', 'PT Cruiser'],
      class: ['Compact', 'Mid Size', 'Full Size'],
    },
    {
      type: 'Dodge',
      body: ['Avenger', 'Charger', 'Dart'],
      class: ['Compact', 'Mid Size', 'Full Size'],
    },
    {
      type: 'Fiat',
      body: ['500', 'Panda', 'Tipo'],
      class: ['Subcompact', 'Compact'],
    },
    {
      type: 'Ford',
      body: ['Fiesta', 'Focus', 'Fusion', 'Taurus'],
      class: ['Subcompact', 'Compact', 'Mid Size', 'Full Size'],
    },
    {
      type: 'Genesis',
      body: ['G70', 'G80', 'G90'],
      class: ['Compact', 'Mid Size', 'Full Size'],
    },
    {
      type: 'GMC',
      body: [],
      class: [],
    },
    {
      type: 'Honda',
      body: ['Accord', 'Civic', 'Fit'],
      class: ['Subcompact', 'Compact', 'Mid Size'],
    },
    {
      type: 'Hyundai',
      body: ['Accent', 'Azera', 'Elantra', 'Genesis', 'Sonata'],
      class: ['Subcompact', 'Compact', 'Mid Size', 'Full Size'],
    },
    {
      type: 'Infiniti',
      body: ['Q30', 'Q50', 'Q70'],
      class: ['Compact', 'Mid Size', 'Full Size'],
    },
    {
      type: 'Jaguar',
      body: ['XE', 'XF', 'XJ'],
      class: ['Compact', 'Mid Size', 'Full Size'],
    },
    {
      type: 'Kia',
      body: ['Cadenza', 'Forte', 'K900', 'Optima', 'Rio', 'Soul', 'Stinger'],
      class: ['Subcompact', 'Compact', 'Mid Size', 'Full Size'],
    },
    {
      type: 'Lexus',
      body: ['ES', 'GS', 'IS', 'LS'],
      class: ['Compact', 'Mid Size', 'Full Size'],
    },
    {
      type: 'Lincoln',
      body: ['Continental', 'MKS', 'MKZ'],
      class: ['Mid Size', 'Full Size'],
    },
    {
      type: 'Mazda',
      body: ['Mazda2', 'Mazda3', 'Mazda6'],
      class: ['Subcompact', 'Compact', 'Mid Size'],
    },
    {
      type: 'Mercedes Benz',
      body: ['A Class', 'B Class', 'C Class', 'E Class', 'S Class', 'CLA'],
      class: ['Subcompact', 'Compact', 'Mid Size', 'Full Size'],
    },
    {
      type: 'Mini',
      body: ['Clubman', 'Cooper'],
      class: ['Subcompact'],
    },
    {
      type: 'Nissan',
      body: ['Altima', 'Leaf', 'Maxima', 'Micra', 'Sentra', 'Versa'],
      class: ['Subcompact', 'Compact', 'Mid Size', 'Full Size'],
    },
    {
      type: 'Subaru',
      body: ['Impreza', 'Legacy'],
      class: ['Compact', 'Mid Size'],
    },
    {
      type: 'Suzuki',
      body: ['Swift'],
      class: ['Subcompact'],
    },
    {
      type: 'Tesla',
      body: ['Model 3', 'Model S'],
      class: ['Compact', 'Full Size'],
    },
    {
      type: 'Toyota',
      body: ['Avalon', 'Camry', 'Corolla', 'Yaris'],
      class: ['Subcompact', 'Compact', 'Mid Size', 'Full Size'],
    },
    {
      type: 'Volkswagen',
      body: ['Arteon', 'Golf', 'Jetta', 'Passat', 'Polo'],
      class: ['Subcompact', 'Compact', 'Mid Size', 'Full Size'],
    },
    {
      type: 'Volvo',
      body: ['S60', 'S90', 'V60'],
      class: ['Compact', 'Full Size'],
    },
  ],
  SUV: [
    {
      type: 'Acura',
      body: ['MDX', 'RDX'],
      class: ['Compact', 'Mid Size'],
    },
    {
      type: 'Audi',
      body: ['Q3', 'Q5', 'Q7', 'Q8'],
      class: ['Compact', 'Mid Size'],
    },
    {
      type: 'BMW',
      body: ['X1', 'X2', 'X3', 'X4', 'X5', 'X6', 'X7'],
      class: ['Compact', 'Mid Size', 'Full Size'],
    },
    {
      type: 'Buick',
      body: ['Enclave', 'Encore', 'Envision'],
      class: ['Compact', 'Mid Size'],
    },
    {
      type: 'Cadillac',
      body: ['XT4', 'XT5', 'XT6', 'Escalade', 'Escalade ESV'],
      class: ['Compact', 'Mid Size', 'Full Size', 'Full Size XL'],
    },
    {
      type: 'Chevrolet',
      body: ['Blazer', 'Equinox', 'Suburban', 'Tahoe', 'Trax', 'Traverse'],
      class: ['Compact', 'Mid Size', 'Full Size', 'Full Size XL'],
    },
    {
      type: 'Dodge',
      body: ['Durango', 'Journey'],
      class: ['Mid Size'],
    },
    {
      type: 'Fiat',
      body: ['500X'],
      class: ['Compact'],
    },
    {
      type: 'Ford',
      body: [
        'Bronco',
        'EcoSport',
        'Edge',
        'Escape',
        'Expedition',
        'Expedition Max',
        'Explorer',
      ],
      class: ['Compact', 'Mid Size', 'Full Size', 'Full Size XL'],
    },
    {
      type: 'Genesis',
      body: ['GV70', 'GV80'],
      class: ['Compact', 'Mid Size'],
    },
    {
      type: 'GMC',
      body: ['Acadia', 'Terrain', 'Yukon', 'Yukon XL'],
      class: ['Compact', 'Mid Size', 'Full Size', 'Full Size XL'],
    },
    {
      type: 'Honda',
      body: ['CR-V', 'HR-V', 'Passport', 'Pilot'],
      class: ['Compact', 'Mid Size'],
    },
    {
      type: 'Hyundai',
      body: ['Kona', 'Palisade', 'Santa Fe', 'Tucson'],
      class: ['Compact', 'Mid Size'],
    },
    {
      type: 'Infiniti',
      body: ['QX30', 'QX50', 'QX60', 'QX80'],
      class: ['Compact', 'Mid Size', 'Full Size'],
    },
    {
      type: 'Jeep',
      body: ['Cherokee', 'Compass', 'Grand Cherokee', 'Renegade', 'Wrangler'],
      class: ['Compact', 'Mid Size'],
    },
    {
      type: 'Kia',
      body: ['Seltos', 'Sorento', 'Soul', 'Sportage', 'Telluride'],
      class: ['Compact', 'Mid Size'],
    },
    {
      type: 'Land Rover',
      body: [
        'Discovery',
        'Discovery Sport',
        'Range Rover',
        'Range Rover Evoque',
        'Range Rover Sport',
        'Range Rover Velar',
      ],
      class: ['Compact', 'Mid Size', 'Full Size'],
    },
    {
      type: 'Lexus',
      body: ['GX', 'LX', 'NX', 'RX', 'UX'],
      class: ['Compact', 'Mid Size', 'Full Size'],
    },
    {
      type: 'Lincoln',
      body: ['Aviator', 'Corsair', 'Nautilus', 'Navigator'],
      class: ['Compact', 'Mid Size', 'Full Size'],
    },
    {
      type: 'Mazda',
      body: ['CX-3', 'CX-30', 'CX-5', 'CX-9'],
      class: ['Compact', 'Mid Size'],
    },
    {
      type: 'Mercedes Benz',
      body: [
        'GLA',
        'GLB',
        'GLC',
        'GLC Coupe',
        'GLE',
        'GLE Coupe',
        'GLS',
        'G Class',
      ],
      class: ['Compact', 'Mid Size', 'Full Size'],
    },
    {
      type: 'Mini',
      body: ['Countryman'],
      class: ['Compact'],
    },
    {
      type: 'Nissan',
      body: ['Armada', 'Kicks', 'Murano', 'Pathfinder', 'Rogue', 'Rogue Sport'],
      class: ['Compact', 'Mid Size', 'Full Size'],
    },
    {
      type: 'Rivian',
      body: ['R1S'],
      class: ['Mid Size'],
    },
    {
      type: 'Subaru',
      body: ['Ascent', 'Crosstrek', 'Forester', 'Outback'],
      class: ['Compact', 'Mid Size'],
    },
    {
      type: 'Tesla',
      body: ['Model Y', 'Model X'],
      class: ['Compact', 'Mid Size'],
    },
    {
      type: 'Toyota',
      body: [
        'C-HR',
        'Highlander',
        'Land Cruiser',
        'RAV4',
        'Sequoia',
        '4Runner',
      ],
      class: ['Compact', 'Mid Size', 'Full Size'],
    },
    {
      type: 'Volkswagen',
      body: ['Atlas', 'Atlas Cross Sport', 'Tiguan'],
      class: ['Compact', 'Mid Size'],
    },
    {
      type: 'Volvo',
      body: ['XC40', 'XC60', 'XC90'],
      class: ['Compact', 'Mid Size', 'Full Size'],
    },
  ],
  Truck: [
    {
      type: 'Chevrolet',
      body: [
        'Colorado',
        'Silverado 1500',
        'Silverado 2500HD',
        'Silverado 3500HD',
        'Silverado 4500HD',
        'Silverado 5500HD',
        'Silverado 6500HD',
      ],
      class: [
        'Light Duty - Class 1',
        'Light Duty - Class 2',
        'Light Duty - Class 3',
        'Medium Duty - Class 4',
        'Medium Duty - Class 5',
        'Medium Duty - Class 6',
      ],
    },
    {
      type: 'DAF Trucks',
      body: ['CF', 'LF', 'XF'],
      class: [
        'Medium Duty - Class 6',
        'Medium Duty - Class 7',
        'Medium Duty - Class 8',
      ],
    },
    {
      type: 'Ford',
      body: [
        'Ranger',
        'F-150',
        'F-250',
        'F-350',
        'F-450',
        'F-550',
        'F-650',
        'F-750',
      ],
      class: [
        'Light Duty - Class 1',
        'Light Duty - Class 2',
        'Light Duty - Class 3',
        'Medium Duty - Class 4',
        'Medium Duty - Class 5',
        'Medium Duty - Class 6',
        'Heavy Duty - Class 7',
      ],
    },
    {
      type: 'Freightliner',
      body: ['M2 106', 'M2 112', 'Cascadia', '114SD', '122SD'],
      class: [
        'Medium Duty - Class 6',
        'Heavy Duty - Class 7',
        'Heavy Duty - Class 8',
      ],
    },
    {
      type: 'GMC',
      body: [
        'Canyon',
        'Sierra 1500',
        'Sierra 2500HD',
        'Sierra 3500HD',
        'Sierra 4500HD',
        'Sierra 5500HD',
        'Sierra 6500HD',
      ],
      class: [
        'Light Duty - Class 1',
        'Light Duty - Class 2',
        'Light Duty - Class 3',
        'Medium Duty - Class 4',
        'Medium Duty - Class 5',
        'Medium Duty - Class 6',
      ],
    },
    {
      type: 'Hino',
      body: ['300 Series', '500 Series', '700 Series'],
      class: [
        'Light Duty - Class 2',
        'Light Duty - Class 3',
        'Medium Duty - Class 4',
        'Medium Duty - Class 5',
        'Medium Duty - Class 6',
        'Heavy Duty - Class 7',
        'Heavy Duty - Class 8',
      ],
    },
    {
      type: 'Honda',
      body: ['Ridgeline'],
      class: ['Light Duty - Class 2'],
    },
    {
      type: 'International',
      body: ['MV Series', 'CV Series', 'LT Series', 'HX Series', 'RH Series'],
      class: [
        'Medium Duty - Class 4',
        'Medium Duty - Class 5',
        'Medium Duty - Class 6',
        'Heavy Duty - Class 8',
      ],
    },
    {
      type: 'Isuzu',
      body: ['N Series', 'F Series', 'C&E Series'],
      class: [
        'Light Duty - Class 2',
        'Light Duty - Class 3',
        'Medium Duty - Class 4',
        'Medium Duty - Class 5',
        'Medium Duty - Class 6',
        'Heavy Duty - Class 7',
        'Heavy Duty - Class 8',
      ],
    },
    {
      type: 'Iveco',
      body: ['Stralis', 'Trakker', 'Eurocargo', 'Daily'],
      class: [
        'Light Duty - Class 2',
        'Light Duty - Class 3',
        'Medium Duty - Class 6',
        'Heavy Duty - Class 7',
        'Heavy Duty - Class 8',
      ],
    },
    {
      type: 'Kenworth',
      body: ['T680', 'T880', 'W990', 'T370', 'T270', 'C500'],
      class: [
        'Medium Duty - Class 6',
        'Heavy Duty - Class 7',
        'Heavy Duty - Class 8',
        'Super Heavy Duty - Specialized',
      ],
    },
    {
      type: 'Mack',
      body: ['Anthem', 'Granite', 'Pinnacle', 'LR', 'TerraPro'],
      class: ['Heavy Duty - Class 8'],
    },
    {
      type: 'MAN',
      body: ['TGX', 'TGS', 'TGM', 'TGL'],
      class: [
        'Medium Duty - Class 6',
        'Heavy Duty - Class 7',
        'Heavy Duty - Class 8',
      ],
    },
    {
      type: 'Mercedes Benz',
      body: ['Actros', 'Arocs', 'Atego', 'Econic', 'Unimog'],
      class: [
        'Medium Duty - Class 5',
        'Medium Duty - Class 6',
        'Heavy Duty - Class 7',
        'Heavy Duty - Class 8',
      ],
    },
    {
      type: 'Mitsubishi Fuso',
      body: ['Canter', 'Fighter', 'Super Great'],
      class: [
        'Light Duty - Class 2',
        'Light Duty - Class 3',
        'Medium Duty - Class 4',
        'Medium Duty - Class 5',
        'Medium Duty - Class 6',
        'Heavy Duty - Class 7',
        'Heavy Duty - Class 8',
      ],
    },
    {
      type: 'Nissan',
      body: ['Frontier', 'Titan'],
      class: ['Light Duty - Class 1', 'Light Duty - Class 2'],
    },
    {
      type: 'Peterbilt',
      body: ['579', '567', '389', '337', '330'],
      class: [
        'Medium Duty - Class 6',
        'Heavy Duty - Class 7',
        'Heavy Duty - Class 8',
      ],
    },
    {
      type: 'RAM',
      body: ['RAM 1500', 'RAM 2500', 'RAM 3500', 'RAM 4500', 'RAM 5500'],
      class: [
        'Light Duty - Class 2',
        'Light Duty - Class 3',
        'Medium Duty - Class 4',
        'Medium Duty - Class 5',
      ],
    },
    {
      type: 'Renault',
      body: ['T', 'C', 'K', 'D', 'D Wide'],
      class: [
        'Medium Duty - Class 6',
        'Heavy Duty - Class 7',
        'Heavy Duty - Class 8',
      ],
    },
    {
      type: 'Rivian',
      body: ['R1T'],
      class: ['Light Duty - Class 2'],
    },
    {
      type: 'Scania',
      body: ['R Series', 'S Series', 'P Series', 'G Series'],
      class: [
        'Medium Duty - Class 6',
        'Heavy Duty - Class 7',
        'Heavy Duty - Class 8',
      ],
    },
    {
      type: 'TATA Motors',
      body: ['Prima'],
      class: ['Heavy Duty - Class 7', 'Heavy Duty - Class 8'],
    },
    {
      type: 'Tesla',
      body: ['Cybertruck', 'Semi'],
      class: ['Light Duty - Class 2', 'Heavy Duty - Class 8'],
    },
    {
      type: 'Toyota',
      body: ['Tacoma', 'Tundra'],
      class: ['Light Duty - Class 1', 'Light Duty - Class 2'],
    },
    {
      type: 'UD Trucks',
      body: ['Quon', 'Condor'],
      class: [
        'Medium Duty - Class 4',
        'Medium Duty - Class 5',
        'Medium Duty - Class 6',
        'Heavy Duty - Class 8',
      ],
    },
    {
      type: 'Volvo',
      body: ['FH', 'FM', 'FMX', 'FE', 'FL'],
      class: [
        'Medium Duty - Class 6',
        'Heavy Duty - Class 7',
        'Heavy Duty - Class 8',
      ],
    },
    {
      type: 'Western Star',
      body: ['4700', '4800', '4900', '5700XE', '6900'],
      class: ['Heavy Duty - Class 7', 'Heavy Duty - Class 8'],
    },
  ],
  'School Bus': [
    {
      type: 'Ford',
      body: [
        'Collins SL400',
        'Collins SL408',
        'Collins SH400',
        'Collins SH408',
        'Collins DH400',
        'Collins DH416',
        'Collins DE416WR',
        'Collins DH500',
        'Collins DH516',
        'Collins DE416WR',
        'Collins DE516',
        'Collins DE516WR',
        'Collins TL400',
        'Collins TH400',
        'Collins TL408',
        'Collins TH408',
        'Collins TH500',
        'Collins TH516WR',
        'Starcraft Quest SRW',
        'Trans Tech SST',
        'Trans Tech Aero',
        'Trans Star',
        'Micro Bird G5',
        'Micro Bird T-Series',
        'Van Con',
        'Starcraft Quest XL',
        'Thomas Built Minotaur',
        'Bluebird Microbird',
      ],
      class: ['Type A', 'Type B'],
    },
    {
      type: 'Chevrolet',
      body: [
        'Collins SL400',
        'Collins SL408',
        'Collins SH400',
        'Collins SH408',
        'Collins DH400',
        'Collins DH416',
        'Collins DE416WR',
        'Collins DH500',
        'Collins DH516',
        'Collins DE416WR',
        'Collins DE516',
        'Collins DE516WR',
        'Collins TH400',
        'Starcraft Quest DRW',
        'Micro Bird MB-II',
        'Trans Tech SST',
        'Trans Tech Aero',
        'Van Con',
        'Thomas Built Minotaur',
        'Bluebird Microbird',
      ],
      class: ['Type A'],
    },
    {
      type: 'Freightliner',
      body: [
        'Thomas Built C2',
        'Thomas Built C2 Jouley',
        'Thomas Built EFX',
        'Thomas Built HDX',
      ],
      class: ['Type C', 'Type D'],
    },
    {
      type: 'International',
      body: ['BE', 'CE', 'FE', 'FC', 'RE', 'RC'],
      class: ['Type B', 'Type C', 'Type D'],
    },
    {
      type: 'Lion Electric',
      body: ['The Lion A', 'The Lion C', 'The Lion D'],
      class: ['Type A', 'Type C', 'Type D'],
    },
    {
      type: 'BYD',
      body: ['K7M', 'K8M', 'GreenPower Motor Company', 'EV Star'],
      class: ['Type A', 'Type B', 'Type C'],
    },
    {
      type: 'Blue Bird',
      body: ['Vision', 'All American FE', 'All American RE'],
      class: ['Type C', 'Type D'],
    },
  ],
  'Mini Van': [
    {
      type: 'Chrysler',
      body: ['Pacifica', 'Voyager'],
      class: ['Standard'],
    },
    {
      type: 'Dodge',
      body: ['Grand Caravan'],
      class: ['Standard'],
    },
    {
      type: 'Honda',
      body: ['Odyssey'],
      class: ['Standard'],
    },
    {
      type: 'Kia',
      body: ['Sedona', 'Carnival'],
      class: ['Standard'],
    },
    {
      type: 'Nissan',
      body: ['Quest'],
      class: ['Standard'],
    },
    {
      type: 'Toyota',
      body: ['Sienna'],
      class: ['Standard'],
    },
  ],
  'Passenger Van': [
    {
      type: 'Ford',
      body: ['Transit Connect', 'Transit 150', 'Transit 250', 'Transit 350'],
      class: [
        'Light Duty - Class 1',
        'Light Duty - Class 2',
        'Light Duty - Class 3',
      ],
    },
    {
      type: 'Chevrolet',
      body: ['Express 2500', 'Express 3500'],
      class: ['Light Duty - Class 2', 'Light Duty - Class 3'],
    },
    {
      type: 'GMC',
      body: ['Savana 2500', 'Savana 3500'],
      class: ['Light Duty - Class 2', 'Light Duty - Class 3'],
    },
    {
      type: 'Nissan',
      body: ['NV 200', 'NV 3500'],
      class: ['Light Duty - Class 1', 'Light Duty - Class 3'],
    },
    {
      type: 'Mercedes Benz',
      body: ['Metris', 'Sprinter 2500', 'Sprinter 3500'],
      class: [
        'Light Duty - Class 1',
        'Light Duty - Class 2',
        'Light Duty - Class 3',
      ],
    },
    {
      type: 'RAM',
      body: [
        'ProMaster City',
        'ProMaster 1500',
        'ProMaster 2500',
        'ProMaster 3500',
      ],
      class: [
        'Light Duty - Class 1',
        'Light Duty - Class 2',
        'Light Duty - Class 3',
      ],
    },
  ],
  'Utility Van': [
    {
      type: 'Ford',
      body: ['Transit Connect', 'Transit 150', 'Transit 250', 'Transit 350'],
      class: [
        'Light Duty - Class 1',
        'Light Duty - Class 2',
        'Light Duty - Class 3',
      ],
    },
    {
      type: 'Chevrolet',
      body: ['Express 2500', 'Express 3500'],
      class: ['Light Duty - Class 2', 'Light Duty - Class 3'],
    },
    {
      type: 'Citroen',
      body: ['Berlingo', 'Dispatch', 'Relay'],
      class: [
        'Light Duty - Class 1',
        'Light Duty - Class 2',
        'Light Duty - Class 3',
      ],
    },
    {
      type: 'GMC',
      body: ['Savana 2500', 'Savana 3500'],
      class: ['Light Duty - Class 2', 'Light Duty - Class 3'],
    },
    {
      type: 'Nissan',
      body: ['NV 200', 'NV 3500'],
      class: ['Light Duty - Class 1', 'Light Duty - Class 3'],
    },
    {
      type: 'Mercedes Benz',
      body: ['Metris', 'Sprinter 2500', 'Sprinter 3500'],
      class: [
        'Light Duty - Class 1',
        'Light Duty - Class 2',
        'Light Duty - Class 3',
      ],
    },
    {
      type: 'Peugeot',
      body: ['Partner', 'Expert', 'Boxer'],
      class: [
        'Light Duty - Class 1',
        'Light Duty - Class 2',
        'Light Duty - Class 3',
      ],
    },
    {
      type: 'RAM',
      body: [
        'ProMaster City',
        'ProMaster 1500',
        'ProMaster 2500',
        'ProMaster 3500',
      ],
      class: [
        'Light Duty - Class 1',
        'Light Duty - Class 2',
        'Light Duty - Class 3',
      ],
    },
    {
      type: 'Renault',
      body: ['Kangoo', 'Trafic', 'Master'],
      class: ['Light Duty - Class 1', 'Light Duty - Class 2'],
    },
    {
      type: 'Genesis',
      body: ['Delivery 500', 'Delivery 700'],
      class: ['Light Duty - Class 2'],
    },
    {
      type: 'Volkswagen',
      body: ['Caddy', 'Transporter', 'Crafter'],
      class: [
        'Light Duty - Class 1',
        'Light Duty - Class 2',
        'Light Duty - Class 3',
      ],
    },
  ],
  'Motor Coach': [
    {
      type: 'Alexander Dennis',
      body: ['Enviro series'],
      class: [...motorCoachClasses],
    },
    {
      type: 'Ankai',
      body: ['HFF Series'],
      class: [...motorCoachClasses],
    },
    {
      type: 'Ashok Leyland',
      body: ['Luxura Magical Series', 'Falcon Series'],
      class: [...motorCoachClasses],
    },
    {
      type: 'Ayats',
      body: ['Bravo series'],
      class: [...motorCoachClasses],
    },
    {
      type: 'BCI',
      body: ['Classmaster Series', 'Explorer Series'],
      class: [...motorCoachClasses],
    },
    {
      type: 'Caio',
      body: ['G3400', 'G3600'],
      class: [...motorCoachClasses],
    },
    {
      type: 'Champion Bus',
      body: ['Defender', 'Challenger'],
      class: [...motorCoachClasses],
    },
    {
      type: 'Comil',
      body: ['Campione Series', 'Invictus Series'],
      class: [...motorCoachClasses],
    },
    {
      type: 'Golden Dragon',
      body: ['XML Series'],
      class: [...motorCoachClasses],
    },
    {
      type: 'Higer',
      body: ['KLQ Series'],
      class: [...motorCoachClasses],
    },
    {
      type: 'Hyundai',
      body: ['Universe', 'Aero Series'],
      class: [...motorCoachClasses],
    },
    {
      type: 'Irizar',
      body: ['i6', 'i8'],
      class: [...motorCoachClasses],
    },
    {
      type: 'Iveco Bus',
      body: ['Magelys', 'Evadys'],
      class: [...motorCoachClasses],
    },
    {
      type: 'Jonckheere',
      body: ['Arrow', 'JSD Series'],
      class: [...motorCoachClasses],
    },
    {
      type: 'King Long',
      body: ['XMQ Series'],
      class: [...motorCoachClasses],
    },
    {
      type: 'MAN',
      body: ["Lion's Coach", "Lion's Intercity"],
      class: [...motorCoachClasses],
    },
    {
      type: 'Marcopolo',
      body: ['Paradiso Series', 'Viaggio Series'],
      class: [...motorCoachClasses],
    },
    {
      type: 'MCI',
      body: ['J4500', 'D4500', 'D4505'],
      class: [...motorCoachClasses],
    },
    {
      type: 'Mercedes Benz',
      body: ['Travego', 'Tourismo'],
      class: [...motorCoachClasses],
    },
    {
      type: 'Mitsubishi',
      body: ['Aero Queen', 'Aero Ace'],
      class: [...motorCoachClasses],
    },
    {
      type: 'Neoplan',
      body: ['Skyliner', 'Cityliner', 'Tourliner'],
      class: [...motorCoachClasses],
    },
    {
      type: 'New Flyer',
      body: ['Xcelsior Series'],
      class: [...motorCoachClasses],
    },
    {
      type: 'revost',
      body: ['H3-45', 'X3-45', 'H3-41'],
      class: [...motorCoachClasses],
    },
    {
      type: 'Scania',
      body: ['Touring', 'Interlink'],
      class: [...motorCoachClasses],
    },
    {
      type: 'Setra',
      body: ['S 417 TC', 'S 407 CC'],
      class: [...motorCoachClasses],
    },
    {
      type: 'Solaris',
      body: ['Urbino Series'],
      class: [...motorCoachClasses],
    },
    {
      type: 'TATA Motors',
      body: ['Starbus Series', 'Magna Series'],
      class: [...motorCoachClasses],
    },
    {
      type: 'TEMSA',
      body: ['TS 35E', 'TS 45'],
      class: [...motorCoachClasses],
    },
    {
      type: 'Van Hool',
      body: ['CX Series', 'TX Series', 'TDX Series'],
      class: [...motorCoachClasses],
    },
    {
      type: 'VDL',
      body: ['Futura FHD2', 'Futura FDD2'],
      class: [...motorCoachClasses],
    },
    {
      type: 'Volgren',
      body: ['Endura', 'Optimus'],
      class: [...motorCoachClasses],
    },
    {
      type: 'Volvo',
      body: ['9700', '9900'],
      class: [...motorCoachClasses],
    },
    {
      type: 'Yutong',
      body: ['ZK Series'],
      class: [...motorCoachClasses],
    },
    {
      type: 'Zhongtong',
      body: ['LCK Series'],
      class: [...motorCoachClasses],
    },
  ],
}

export const VEHICLE_INCLUDE_OPTIONS = [
  { label: 'Adaptive Cruise Control', value: 'adaptiveCruiseControl' },
  { label: 'Air Conditioning', value: 'airConditioning' },
  { label: 'All Wheel Drive (AWD)', value: 'allWheelDrive' },
  { label: 'Automatic Emergency Braking', value: 'automaticEmergencyBraking' },
  { label: 'Blind Spot Monitoring', value: 'blindSpotMonitoring' },
  { label: 'Bluetooth Connectivity', value: 'bluetoothConnectivity' },
  { label: 'Cross-Traffic Alert', value: 'crossTrafficAlert' },
  {
    label: 'Driver Drowsiness Monitoring',
    value: 'driverDrowsinessMonitoring',
  },
  { label: 'Fog Lights', value: 'fogLights' },
  { label: 'Four Wheel Drive (4WD)', value: 'fourWheelDrive' },
  { label: 'Heated Mirrors', value: 'heatedMirrors' },
  { label: 'Heated Seats', value: 'heatedSeats' },
  { label: 'Lane Departure Warning', value: 'laneDepartureWarning' },
  { label: 'Lane Keeping Assist', value: 'laneKeepingAssist' },
  { label: 'Leather Upholstery', value: 'leatherUpholstery' },
  { label: 'LED Headlights & Taillights', value: 'ledHeadlightsTaillights' },
  { label: 'Navigation System', value: 'navigationSystem' },
  { label: 'Off Road Suspension Package', value: 'offRoadSuspensionPackage' },
  { label: 'Parking Assist Systems', value: 'parkingAssistSystems' },
  { label: 'Parking Sensors', value: 'parkingSensors' },
  { label: 'Power Mirrors', value: 'powerMirrors' },
  { label: 'Premium Sound System', value: 'premiumSoundSystem' },
  { label: 'Rearview Camera', value: 'rearviewCamera' },
  { label: 'Running Boards', value: 'runningBoards' },
  { label: 'Sunroof/Moonroof', value: 'sunroofMoonroof' },
  { label: 'Towing Package', value: 'towingPackage' },
  { label: 'Trailer Brake Controller', value: 'trailerBrakeController' },
]

export const VEHICLE_ENDORSEMENT_OPTIONS = [
  { value: 'pEndorsement', label: 'P Endorsement' },
  { value: 'hEndorsement', label: 'H Endorsement' },
  { value: 'nEndorsement', label: 'N Endorsement' },
  { value: 'tEndorsement', label: 'T Endorsement' },
  { value: 'sEndorsement', label: 'S Endorsement' },
  { value: 'xEndorsement', label: 'X Endorsement' },
]

export const VEHICLE_LICENSE_PERMITTED_OPTIONS = [
  { value: 'cdlA', label: 'CDL - A' },
  { value: 'cdlB', label: 'CDL - B' },
  { value: 'cdlC', label: 'CDL - C' },
  { value: 'nonCdlD', label: 'Non CDL - D' },
  { value: 'nonCdlE', label: 'Non CDL - E' },
]

export const REGISTERED_STATE = [
  { label: 'Alabama - AL', value: 'AL', state: 'Alabama' },
  { label: 'Alaska - AK', value: 'AK', state: 'Alaska' },
  { label: 'Arizona - AZ', value: 'AZ', state: 'Arizona' },
  { label: 'Arkansas - AR', value: 'AR', state: 'Arkansas' },
  { label: 'California - CA', value: 'CA', state: 'California' },
  { label: 'Colorado - CO', value: 'CO', state: 'Colorado' },
  { label: 'Connecticut - CT', value: 'CT', state: 'Connecticut' },
  { label: 'Delaware - DE', value: 'DE', state: 'Delaware' },
  { label: 'Florida - FL', value: 'FL', state: 'Florida' },
  { label: 'Georgia - GA', value: 'GA', state: 'Georgia' },
  { label: 'Hawaii - HI', value: 'HI', state: 'Hawaii' },
  { label: 'Idaho - ID', value: 'ID', state: 'Idaho' },
  { label: 'Illinois - IL', value: 'IL', state: 'Illinois' },
  { label: 'Indiana - IN', value: 'IN', state: 'Indiana' },
  { label: 'Iowa - IA', value: 'IA', state: 'Iowa' },
  { label: 'Kansas - KS', value: 'KS', state: 'Kansas' },
  { label: 'Kentucky - KY', value: 'KY', state: 'Kentucky' },
  { label: 'Louisiana - LA', value: 'LA', state: 'Louisiana' },
  { label: 'Maine - ME', value: 'ME', state: 'Maine' },
  { label: 'Maryland - MD', value: 'MD', state: 'Maryland' },
  { label: 'Massachusetts - MA', value: 'MA', state: 'Massachusetts' },
  { label: 'Michigan - MI', value: 'MI', state: 'Michigan' },
  { label: 'Minnesota - MN', value: 'MN', state: 'Minnesota' },
  { label: 'Mississippi - MS', value: 'MS', state: 'Mississippi' },
  { label: 'Missouri - MO', value: 'MO', state: 'Missouri' },
  { label: 'Montana - MT', value: 'MT', state: 'Montana' },
  { label: 'Nebraska - NE', value: 'NE', state: 'Nebraska' },
  { label: 'Nevada - NV', value: 'NV', state: 'Nevada' },
  { label: 'New Hampshire - NH', value: 'NH', state: 'New Hampshire' },
  { label: 'New Jersey - NJ', value: 'NJ', state: 'New Jersey' },
  { label: 'New Mexico - NM', value: 'NM', state: 'New Mexico' },
  { label: 'New York - NY', value: 'NY', state: 'New York' },
  { label: 'North Carolina - NC', value: 'NC', state: 'North Carolina' },
  { label: 'North Dakota - ND', value: 'ND', state: 'North Dakota' },
  { label: 'Ohio - OH', value: 'OH', state: 'Ohio' },
  { label: 'Oklahoma - OK', value: 'OK', state: 'Oklahoma' },
  { label: 'Oregon - OR', value: 'OR', state: 'Oregon' },
  { label: 'Pennsylvania - PA', value: 'PA', state: 'Pennsylvania' },
  { label: 'Rhode Island - RI', value: 'RI', state: 'Rhode Island' },
  { label: 'South Carolina - SC', value: 'SC', state: 'South Carolina' },
  { label: 'South Dakota - SD', value: 'SD', state: 'South Dakota' },
  { label: 'Tennessee - TN', value: 'TN', state: 'Tennessee' },
  { label: 'Texas - TX', value: 'TX', state: 'Texas' },
  { label: 'Utah - UT', value: 'UT', state: 'Utah' },
  { label: 'Vermont - VT', value: 'VT', state: 'Vermont' },
  { label: 'Virginia - VA', value: 'VA', state: 'Virginia' },
  { label: 'Washington - WA', value: 'WA', state: 'Washington' },
  { label: 'West Virginia - WV', value: 'WV', state: 'West Virginia' },
  { label: 'Wisconsin - WI', value: 'WI', state: 'Wisconsin' },
  { label: 'Wyoming - WY', value: 'WY', state: 'Wyoming' },
]

export const WHEELCHAIR_CAPACITY_OPTIONS = [
  { label: '1 Persons', value: '1' },
  { label: '2 Persons', value: '2' },
  { label: '3 Persons', value: '3' },
  { label: '4 Persons', value: '4' },
  { label: '5 Persons', value: '5' },
  { label: '6 Persons', value: '6' },
  { label: '7 Persons', value: '7' },
  { label: '8 Persons', value: '8' },
  { label: '9 Persons', value: '9' },
  { label: '10 Persons', value: '10' },
  { label: '11 Persons', value: '11' },
  { label: '12 Persons', value: '12' },
  { label: '13 Persons', value: '13' },
  { label: '14 Persons', value: '14' },
  { label: '15 Persons', value: '15' },
  { label: '16 Persons', value: '16' },
]

export const POWERSOURCE_OPTIONS = [
  { label: 'Biodiesel', value: 'Biodiesel' },
  { label: 'Diesel', value: 'Diesel' },
  { label: 'Electric', value: 'Electric' },
  { label: 'Ethanol', value: 'Ethanol' },
  { label: 'Gasoline', value: 'Gasoline' },
  { label: 'Hydrogen', value: 'Hydrogen' },
  { label: 'Hybrid', value: 'Hybrid' },
  { label: 'Natural Gas', value: 'Natural Gas' },
  { label: 'Propane', value: 'Propane' },
]

export const POWERRANGE_OPTIONS = {
  a: [
    { label: '10 Gallons', value: '10 gallons' },
    { label: '12 Gallons', value: '12 gallons' },
    { label: '16 Gallons', value: '16 gallons' },
    { label: '20 Gallons', value: '20 gallons' },
    { label: '25 Gallons', value: '25 gallons' },
    { label: '30 Gallons', value: '30 gallons' },
    { label: '35 Gallons', value: '35 gallons' },
    { label: '40 Gallons', value: '40 gallons' },
    { label: '45 Gallons', value: '45 gallons' },
    { label: '50 Gallons', value: '50 gallons' },
    { label: '65 Gallons', value: '65 gallons' },
    { label: '75 Gallons', value: '75 gallons' },
    { label: '100 Gallons', value: '100 gallons' },
    { label: '125 Gallons', value: '125 gallons' },
    { label: '150 Gallons', value: '150 gallons' },
    { label: '175 Gallons', value: '175 gallons' },
    { label: '200 Gallons', value: '200 gallons' },
    { label: '225 Gallons', value: '225 gallons' },
    { label: '250 Gallons', value: '250 gallons' },
    { label: '275 Gallons', value: '275 gallons' },
    { label: '300 Gallons', value: '300 gallons' },
    { label: '325 Gallons', value: '325 gallons' },
    { label: '350 Gallons', value: '350 gallons' },
    { label: '375 Gallons', value: '375 gallons' },
    { label: '400 Gallons', value: '400 gallons' },
    { label: '425 Gallons', value: '425 gallons' },
    { label: '450 Gallons', value: '450 gallons' },
  ],
  b: [
    { label: '100 Miles', value: '100 Miles' },
    { label: '125 Miles', value: '125 Miles' },
    { label: '150 Miles', value: '150 Miles' },
    { label: '175 Miles', value: '175 Miles' },
    { label: '200 Miles', value: '200 Miles' },
    { label: '225 Miles', value: '225 Miles' },
    { label: '250 Miles', value: '250 Miles' },
    { label: '275 Miles', value: '275 Miles' },
    { label: '300 Miles', value: '300 Miles' },
    { label: '325 Miles', value: '325 Miles' },
    { label: '350 Miles', value: '350 Miles' },
    { label: '375 Miles', value: '375 Miles' },
    { label: '400 Miles', value: '400 Miles' },
    { label: '425 Miles', value: '425 Miles' },
    { label: '450 Miles', value: '450 Miles' },
    { label: '475 Miles', value: '475 Miles' },
    { label: '500 Miles', value: '500 Miles' },
    { label: '525 Miles', value: '525 Miles' },
    { label: '550 Miles', value: '550 Miles' },
    { label: '575 Miles', value: '575 Miles' },
    { label: '600 Miles', value: '600 Miles' },
    { label: '625 Miles', value: '100 Miles' },
    { label: '650 Miles', value: '650 Miles' },
  ],
  c: [
    { label: '10 Gallons - 100 Miles', value: '10 Gallons - 100 Miles' },
    { label: '10 Gallons - 180 Miles', value: '10 Gallons - 180 Miles' },
    { label: '10 Gallons - 250 Miles', value: '10 Gallons - 250 Miles' },
    { label: '16 Gallons - 160 Miles', value: '16 Gallons - 160 Miles' },
    { label: '16 Gallons - 288 Miles', value: '16 Gallons - 288 Miles' },
    { label: '16 Gallons - 400 Miles', value: '16 Gallons - 400 Miles' },
    { label: '20 Gallons - 200 Miles', value: '20 Gallons - 200 Miles' },
    { label: '20 Gallons - 360 Miles', value: '20 Gallons - 360 Miles' },
    { label: '20 Gallons - 500 Miles', value: '20 Gallons - 500 Miles' },
    { label: '25 Gallons - 250 Miles', value: '25 Gallons - 250 Miles' },
    { label: '25 Gallons - 450 Miles', value: '25 Gallons - 450 Miles' },
    { label: '25 Gallons - 625 Miles', value: '25 Gallons - 625 Miles' },
    { label: '50 Gallons - 500 Miles', value: '50 Gallons - 500 Miles' },
    { label: '50 Gallons - 900 Miles', value: '50 Gallons - 900 Miles' },
    { label: '50 Gallons - 1250 Miles', value: '50 Gallons - 1250 Miles' },
    { label: '75 Gallons - 750 Miles', value: '75 Gallons - 750 Miles' },
    { label: '75 Gallons - 1350 Miles', value: '75 Gallons - 1350 Miles' },
    { label: '75 Gallons - 1875 Miles', value: '75 Gallons - 1875 Miles' },
    { label: '100 Gallons - 1000 Miles', value: '100 Gallons - 1000 Miles' },
    { label: '100 Gallons - 1800 Miles', value: '100 Gallons - 1800 Miles' },
    { label: '100 Gallons - 2500 Miles', value: '100 Gallons - 2500 Miles' },
    { label: '125 Gallons - 1250 Miles', value: '125 Gallons - 1250 Miles' },
    { label: '125 Gallons - 2250 Miles', value: '125 Gallons - 2250 Miles' },
    { label: '125 Gallons - 3125 Miles', value: '125 Gallons - 3125 Miles' },
    { label: '150 Gallons - 1500 Miles', value: '150 Gallons - 1500 Miles' },
    { label: '150 Gallons - 2700 Miles', value: '150 Gallons - 2700 Miles' },
    { label: '150 Gallons - 3750 Miles', value: '150 Gallons - 3750 Miles' },
    { label: '175 Gallons - 1750 Miles', value: '175 Gallons - 1750 Miles' },
    { label: '175 Gallons - 3150 Miles', value: '175 Gallons - 3150 Miles' },
    { label: '175 Gallons - 4375 Miles', value: '175 Gallons - 4375 Miles' },
    { label: '200 Gallons - 2000 Miles', value: '200 Gallons - 2000 Miles' },
    { label: '200 Gallons - 3600 Miles', value: '200 Gallons - 3600 Miles' },
    { label: '200 Gallons - 5000 Miles', value: '200 Gallons - 5000 Miles' },
    { label: '225 Gallons - 2250 Miles', value: '225 Gallons - 2250 Miles' },
    { label: '225 Gallons - 4050 Miles', value: '225 Gallons - 4050 Miles' },
    { label: '225 Gallons - 5625 Miles', value: '225 Gallons - 5625 Miles' },
    { label: '250 Gallons - 2500 Miles', value: '250 Gallons - 2500 Miles' },
    { label: '250 Gallons - 4500 Miles', value: '250 Gallons - 4500 Miles' },
    { label: '250 Gallons - 6250 Miles', value: '250 Gallons - 6250 Miles' },
    { label: '275 Gallons - 2750 Miles', value: '275 Gallons - 2750 Miles' },
    { label: '275 Gallons - 4950 Miles', value: '275 Gallons - 4950 Miles' },
    { label: '275 Gallons - 6875 Miles', value: '275 Gallons - 6875 Miles' },
    { label: '300 Gallons - 3000 Miles', value: '300 Gallons - 3000 Miles' },
    { label: '300 Gallons - 5400 Miles', value: '300 Gallons - 5400 Miles' },
    { label: '300 Gallons - 7500 Miles', value: '300 Gallons - 7500 Miles' },
  ],
}

export const RIDERSHIP_CAPACITY_OPTIONS = {
  small: [
    { label: '1 Persons', value: '1' },
    { label: '2 Persons', value: '2' },
    { label: '3 Persons', value: '3' },
    { label: '4 Persons', value: '4' },
    { label: '5 Persons', value: '5' },
    { label: '6 Persons', value: '6' },
    { label: '7 Persons', value: '7' },
    { label: '8 Persons', value: '8' },
    { label: '9 Persons', value: '9' },
  ],
  'Passenger Van': [
    { label: '1 Persons', value: '1' },
    { label: '2 Persons', value: '2' },
    { label: '3 Persons', value: '3' },
    { label: '4 Persons', value: '4' },
    { label: '5 Persons', value: '5' },
    { label: '6 Persons', value: '6' },
    { label: '7 Persons', value: '7' },
    { label: '8 Persons', value: '8' },
    { label: '9 Persons', value: '9' },
    { label: '10 Persons', value: '10' },
    { label: '11 Persons', value: '11' },
    { label: '12 Persons', value: '12' },
    { label: '13 Persons', value: '13' },
    { label: '14 Persons', value: '14' },
    { label: '15 Persons', value: '15' },
    { label: '16 Persons', value: '16' },
    { label: '17 Persons', value: '17' },
    { label: '18 Persons', value: '18' },
    { label: '19 Persons', value: '19' },
    { label: '20 Persons', value: '20' },
  ],
  'Type A': [
    { label: '12 Adults / 20 Children', value: '12A / 20C' },
    { label: '13 Adults / 20 Children', value: '13A / 20C' },
    { label: '19 Adults / 28 Children', value: '19A / 28C' },
    { label: '20 Adults / 30 Children', value: '20A / 30C' },
    { label: '21 Adults / 30 Children', value: '21A / 30C' },
  ],
  'Type B': [
    { label: '20 Adults / 30 Children', value: '20A / 30C' },
    { label: '24 Adults / 36 Children', value: '24A / 36C' },
    { label: '28 Adults / 42 Children', value: '28A / 42C' },
  ],
  'Type C': [
    { label: '24 Adults / 36 Children', value: '24A / 36C' },
    { label: '30 Adults / 45 Children', value: '30A / 45C' },
    { label: '40 Adults / 60 Children', value: '40A / 60C' },
    { label: '44 Adults / 66 Children', value: '44A / 66C' },
    { label: '52 Adults / 78 Children', value: '52A / 78C' },
  ],
  'Type D': [
    { label: '36 Adults / 54 Children', value: '36A / 54C' },
    { label: '45 Adults / 68 Children', value: '45A / 68C' },
    { label: '60 Adults / 90 Children', value: '60A / 90C' },
    { label: '52 Adults / 78 Children', value: '52A / 78C' },
  ],
  IL: [
    ...Array.from({ length: 100 }, (_, i) => ({
      label: `${i + 1} Persons`,
      value: `${i + 1}`,
    })),
  ],
}

export const EMPLOYEE_ROLE_OPTIONS = [
  { value: 'Business Personnel', label: 'Business Personnel' },
  { value: 'Business Supervisor', label: 'Business Supervisor' },
  { value: 'Business Manager', label: 'Business Manager' },
  { value: 'Driver', label: 'Driver' },
  { value: 'Driver’s Assistant', label: 'Driver’s Assistant' },
  { value: 'Dispatcher', label: 'Dispatcher' },
  { value: 'Dispatch Supervisor', label: 'Dispatch Supervisor' },
  { value: 'Dispatch Manager', label: 'Dispatch Manager' },
  { value: 'General Manager', label: 'General Manager' },
  { value: 'General Supervisor', label: 'General Supervisor' },
  { value: 'Maintenance Mechanic', label: 'Maintenance Mechanic' },
  { value: 'Maintenance Foreman', label: 'Maintenance Foreman' },
  { value: 'Maintenance Manager', label: 'Maintenance Manager' },
  { value: 'Operations Supervisor', label: 'Operations Supervisor' },
  { value: 'Operations Manager', label: 'Operations Manager' },
  { value: 'Routes Planner', label: 'Routes Planner' },
  { value: 'Routes Supervisor', label: 'Routes Supervisor' },
  { value: 'Routes Manager', label: 'Routes Manager' },
  { value: 'Safety Officer', label: 'Safety Officer' },
  { value: 'Safety Supervisor', label: 'Safety Supervisor' },
  { value: 'Safety Manager', label: 'Safety Manager' },
]

export const EMPLOYEE_TYPE_OPTIONS = [
  { value: 'Driver’s License (Non CDL)', label: 'Driver’s License (Non CDL)' },
  {
    value: 'Commercial Driver’s License (CDL)',
    label: 'Commercial Driver’s License (CDL)',
  },
]

export const EMPLOYEE_ENDORSEMENT_OPTIONS = [
  { value: 'Hazardous Materials - H', label: 'Hazardous Materials - H' },
  { value: 'Tanker Vehicles - N', label: 'Tanker Vehicles - N' },
  { value: 'Passenger Vehicles - P', label: 'Passenger Vehicles - P' },
  { value: 'School - S', label: 'School - S' },
  {
    value: 'Doubles/Triples Trailers - T',
    label: 'Doubles/Triples Trailers - T',
  },
  {
    value: 'Combination of Tanker and Hazardous Materials - X',
    label: 'Combination of Tanker and Hazardous Materials - X',
  },
  { value: 'Motorcycle - M', label: 'Motorcycle - M' },
  { value: 'Tow Truck - W', label: 'Tow Truck - W' },
  { value: 'Firefighter - F', label: 'Firefighter - F' },
  { value: 'Farm Waiver - K', label: 'Farm Waiver - K' },
]

export const EMPLOYEE_RESTRICTION_OPTIONS = [
  { value: 'Corrective Lenses - A', label: 'Corrective Lenses - A' },
  { value: 'Intrastate Only - K', label: 'Intrastate Only - K' },
  { value: 'No Air Brakes - L', label: 'No Air Brakes - L' },
  {
    value: 'Automatic Transmission Only - E',
    label: 'Automatic Transmission Only - E',
  },
  {
    value: 'Limited to Daylight Driving - G',
    label: 'Limited to Daylight Driving - G',
  },
  { value: 'No Manual Transmission - O', label: 'No Manual Transmission - O' },
  { value: 'CDL Intrastate Only - K', label: 'CDL Intrastate Only - K' },
  { value: 'No Tractor Trailer CMV - M', label: 'No Tractor Trailer CMV - M' },
  {
    value: 'No Class A Passenger Vehicle - N',
    label: 'No Class A Passenger Vehicle - N',
  },
  {
    value: 'No Class A or B Passenger Vehicle - P',
    label: 'No Class A or B Passenger Vehicle - P',
  },
  {
    value: 'No Cargo in CMV Tank Vehicle - X',
    label: 'No Cargo in CMV Tank Vehicle - X',
  },
  { value: 'Hearing Aid Required - U', label: 'Hearing Aid Required - U' },
  { value: 'Except Tractor Trailer - Z', label: 'Except Tractor Trailer - Z' },
  { value: 'Medical Variance - V', label: 'Medical Variance - V' },
  {
    value: 'No Photo or Signature - J75',
    label: 'No Photo or Signature - J75',
  },
  {
    value: 'Ignition Interlock Device - I',
    label: 'Ignition Interlock Device - I',
  },
  { value: 'BAIID Only - J90', label: 'BAIID Only - J90' },
]

export const PLACES_URL = 'https://api.mapbox.com/geocoding/v5/mapbox.places'
export const DRIVING_URL = 'https://api.mapbox.com/directions/v5/mapbox/driving'

export const COLORS = [
  '#cc6335',
  '#3f6e1f',
  '#769841',
  '#c7d408',
  '#3344b3',
  '#7e43c5',
  '#1143f7',
  '#795187',
  '#be3b0f',
  '#ac3048',
  '#519818',
  '#ae7ff0',
  '#daee3c',
  '#5750ce',
  '#d388a9',
  '#3fc6e7',
  '#65185c',
  '#7094a1',
  '#2a96b4',
  '#39befe',
  '#052436',
  '#b6a466',
  '#d604ed',
  '#f4fd1a',
  '#933658',
  '#f9ab4e',
  '#459f4d',
  '#8b16fd',
  '#705285',
  '#14f0e6',
  '#e20cc7',
  '#abfaf9',
  '#94a4ee',
  '#e8693e',
  '#38aa4f',
  '#5d51e9',
  '#9284b6',
  '#b12fd1',
  '#5cd831',
  '#6bc6f2',
  '#de4a45',
  '#f8b98e',
  '#9bd189',
  '#b24ef1',
  '#be9de3',
  '#334a88',
  '#0e2f1d',
  '#f7f133',
  '#8b4ad3',
  '#e8d018',
]
