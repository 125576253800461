import Vue from 'vue'
import apiClient from '@/services/axios'
import {
  POWERRANGE_OPTIONS,
  POWERSOURCE_OPTIONS,
  REGISTERED_STATE,
  RIDERSHIP_CAPACITY_OPTIONS,
  VEHICLE_OPTIONS,
  WHEELCHAIR_CAPACITY_OPTIONS,
} from '@/views/tools/routing/Components/RoutingSwitch/Controllers/Constants'

export function createVehicle(payload) {
  return apiClient.post('/vehicles', payload)
}

export function listAllVehicles() {
  return apiClient.get('/vehicles')
}

export function getVehicles(filter) {
  return apiClient.post('/vehicles/searchVehicles', filter)
}

export async function getVehicleByID(id) {
  try {
    const response = await apiClient.get(`/vehicles/${id}`)
    if (response.data) {
      return {
        success: true,
        status: 200,
        message: 'Vehicle data',
        data: response.data,
      }
    } else {
      return {
        success: false,
        status: 404,
        data: {},
        message: `Vehicle with id: ${id} not found!`,
      }
    }
  } catch (error) {
    return {
      success: false,
      status: 500,
      message: 'Error fetching contract by ID',
      error,
    }
  }
}

export function updateVehicle(id, vehicleData) {
  return apiClient.patch(`/vehicles/${id}`, vehicleData)
}

export function deleteVehicle(id) {
  return apiClient.delete(`/vehicles/${id}`)
}

export function deleteVehicles(ids) {
  return apiClient.delete('/vehicles', { data: { ids } })
}

export async function uploadExistingVehicleData(data) {
  const vResponse = await listAllVehicles()
  const allVehicles = vResponse.data
  const yearArray = generateYearOptions()
  const stateShortArray = REGISTERED_STATE.map((item) => item.value)
  const stateFullArray = REGISTERED_STATE.map((item) => item.state)
  const powerSourceArray = POWERSOURCE_OPTIONS.map((item) => item.value)
  const wheelchairArray = WHEELCHAIR_CAPACITY_OPTIONS.map((item) => item.value)
  for (let i = 0; i < data.length; i++) {
    if (!data[i].vehicleIdentifier) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} vehicleIdentifier is empty`,
      })
      return
    }
    if (!data[i].vehicleType) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} vehicleType is empty`,
      })
      return
    }
    if (!data[i].vehicleChassis) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} vehicleChassis is empty`,
      })
      return
    }
    if (!data[i].vehicleBody) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} vehicleBody is empty`,
      })
      return
    }
    if (!data[i].vehicleYear) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} vehicleYear is empty`,
      })
      return
    }
    if (!data[i].registeredState) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} registeredState is empty`,
      })
      return
    }
    if (!data[i].vehicleOwnership) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} vehicleOwnership is empty`,
      })
      return
    }
    if (!data[i].vinNumber) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} vinNumber is empty`,
      })
      return
    }
    if (!data[i].licensePlate) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} licensePlate is empty`,
      })
      return
    }
    if (!data[i].powerSource) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} powerSource is empty`,
      })
      return
    }
    if (!data[i].powerRange) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} powerRange is empty`,
      })
      return
    }
    if (!data[i].ridershipCapacity) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} ridershipCapacity is empty`,
      })
      return
    }
    if (!data[i].wheelchairCapacity?.toString()) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} wheelchairCapacity is empty`,
      })
      return
    }
    if (
      data[i].cdlA?.trim().toLowerCase() !== 'yes' &&
      data[i].cdlB?.trim().toLowerCase() !== 'yes' &&
      data[i].cdlC?.trim().toLowerCase() !== 'yes' &&
      data[i].nonCdlD?.trim().toLowerCase() !== 'yes' &&
      data[i].nonCdlE?.trim().toLowerCase() !== 'yes'
    ) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} At least one should be Yes in License Permitted`,
      })
      return
    }
    if (
      data[i].pEndorsement?.trim().toLowerCase() !== 'yes' &&
      data[i].hEndorsement?.trim().toLowerCase() !== 'yes' &&
      data[i].nEndorsement?.trim().toLowerCase() !== 'yes' &&
      data[i].tEndorsement?.trim().toLowerCase() !== 'yes' &&
      data[i].sEndorsement?.trim().toLowerCase() !== 'yes' &&
      data[i].xEndorsement?.trim().toLowerCase() !== 'yes'
    ) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} At least one should be Yes in Endorsement`,
      })
      return
    }
    const element = data[i]

    const vehicle = allVehicles.filter(
      (vehicle) =>
        vehicle.vehicleId === element.vehicleIdentifier.toString().trim(),
    )
    if (vehicle.length > 0) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} vehicle ID is already exist`,
      })
      return
    }
    if (
      ![
        'Car',
        'SUV',
        'Truck',
        'School Bus',
        'Mini Van',
        'Passenger Van',
        'Utility Van',
        'Motor Coach',
      ].includes(element.vehicleType)
    ) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} vehicle Type is not correct`,
      })
      return
    }
    let chassisOptions = []
    VEHICLE_OPTIONS[element.vehicleType].map((item) =>
      chassisOptions.push(item.type),
    )
    if (!chassisOptions.includes(element.vehicleChassis)) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} vehicle Chassis is not correct`,
      })
      return
    }
    const vehicleChassis = VEHICLE_OPTIONS[element.vehicleType].find(
      (item) => item.type === element.vehicleChassis,
    )
    if (!vehicleChassis.body.includes(element.vehicleBody)) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} vehicle Body is not correct`,
      })
      return
    }
    if (!vehicleChassis.class.includes(element.vehicleClass)) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} vehicle Class is not correct`,
      })
      return
    }
    if (!yearArray.includes(element.vehicleYear.toString())) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} vehicle Year is not correct`,
      })
      return
    }
    if (
      !(
        stateFullArray.includes(element.registeredState) ||
        stateShortArray.includes(element.registeredState)
      )
    ) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} Registered State is not correct`,
      })
      return
    }
    if (!powerSourceArray.includes(element.powerSource)) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} Power Source is not correct`,
      })
      return
    }
    let powerRangeArray = []
    if (
      [
        'Biodiesel',
        'Diesel',
        'Ethanol',
        'Gasoline',
        'Natural Gas',
        'Propane',
      ].includes(element.powerSource)
    ) {
      powerRangeArray = POWERRANGE_OPTIONS['a'].map((item) => item.value)
    } else if (['Electric', 'Hydrogen'].includes(element.powerSource)) {
      powerRangeArray = POWERRANGE_OPTIONS['b'].map((item) => item.value)
    } else if (element.powerSource === 'Hybrid') {
      powerRangeArray = POWERRANGE_OPTIONS['c'].map((item) => item.value)
    }
    if (!powerRangeArray.includes(element.powerRange)) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} Power Range is not correct`,
      })
      return
    }
    let ridershipArray = []
    let ridershipFullArray = []
    if (element.registeredState === 'IL' || element.registeredState === 'Illinois') {
      ridershipArray = RIDERSHIP_CAPACITY_OPTIONS['IL'].map(
        (item) => item.value,
      )
      ridershipFullArray = RIDERSHIP_CAPACITY_OPTIONS['IL'].map(
        (item) => item.label,
      )
    }
    else if (
      ['Car', 'SUV', 'Truck', 'Utility Van', 'Mini Van'].includes(
        element.vehicleType,
      )
    ) {
      ridershipArray = RIDERSHIP_CAPACITY_OPTIONS['small'].map(
        (item) => item.value,
      )
      ridershipFullArray = RIDERSHIP_CAPACITY_OPTIONS['small'].map(
        (item) => item.label,
      )
    } else if ('Passenger Van' === element.vehicleType) {
      ridershipArray = RIDERSHIP_CAPACITY_OPTIONS[element.vehicleType].map(
        (item) => item.value,
      )
      ridershipFullArray = RIDERSHIP_CAPACITY_OPTIONS[element.vehicleType].map(
        (item) => item.label,
      )
    } else if (
      ['School Bus', 'Motor Coach'].includes(element.vehicleType) &&
      element.vehicleClass
    ) {
      ridershipArray = RIDERSHIP_CAPACITY_OPTIONS[element.vehicleClass].map(
        (item) => item.value,
      )
      ridershipFullArray = RIDERSHIP_CAPACITY_OPTIONS[element.vehicleClass].map(
        (item) => item.label,
      )
    }

    if (
      !(
        ridershipArray.includes(element.ridershipCapacity) ||
        ridershipFullArray.includes(element.ridershipCapacity?.toString())
      )
    ) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} Ridership Capacity is not correct`,
      })
      return
    }

    if (
      !(
        wheelchairArray.includes(element.wheelchairCapacity.toString()) ||
        element.wheelchairCapacity.toString() === '0'
      )
    ) {
      Vue.prototype.$notification.warning({
        message: `Row ${i + 1} Wheelchair Capacity is not correct`,
      })
      return
    }
  }
  try {
    let failed = []
    let success = []
    let exists = []
    let response
    for (let v = 0; v < data.length; v++) {
      const element = data[v]
      const optionsIncluded = {
        adaptiveCruiseControl:
          element.adaptiveCruiseControl?.trim().toLowerCase() === 'yes'
            ? true
            : false,
        airConditioning:
          element.airConditioning?.trim().toLowerCase() === 'yes'
            ? true
            : false,
        allWheelDrive:
          element.allWheelDrive?.trim().toLowerCase() === 'yes' ? true : false,
        automaticEmergencyBraking:
          element.automaticEmergencyBraking?.trim().toLowerCase() === 'yes'
            ? true
            : false,
        blindSpotMonitoring:
          element.blindSpotMonitoring?.trim().toLowerCase() === 'yes'
            ? true
            : false,
        bluetoothConnectivity:
          element.bluetoothConnectivity?.trim().toLowerCase() === 'yes'
            ? true
            : false,
        crossTrafficAlert:
          element.crossTrafficAlert?.trim().toLowerCase() === 'yes'
            ? true
            : false,
        bluetoothConnectivity:
          element.bluetoothConnectivity?.trim().toLowerCase() === 'yes'
            ? true
            : false,
        driverDrowsinessMonitoring:
          element.driverDrowsinessMonitoring?.trim().toLowerCase() === 'yes'
            ? true
            : false,
        fogLights:
          element.fogLights?.trim().toLowerCase() === 'yes' ? true : false,
        fourWheelDrive:
          element.fourWheelDrive?.trim().toLowerCase() === 'yes' ? true : false,
        heatedMirrors:
          element.heatedMirrors?.trim().toLowerCase() === 'yes' ? true : false,
        heatedSeats:
          element.heatedSeats?.trim().toLowerCase() === 'yes' ? true : false,
        laneDepartureWarning:
          element.laneDepartureWarning?.trim().toLowerCase() === 'yes'
            ? true
            : false,
        laneKeepingAssist:
          element.laneKeepingAssist?.trim().toLowerCase() === 'yes'
            ? true
            : false,
        leatherUpholstery:
          element.leatherUpholstery?.trim().toLowerCase() === 'yes'
            ? true
            : false,
        ledHeadlightsTaillights:
          element.ledHeadlightsTaillights?.trim().toLowerCase() === 'yes'
            ? true
            : false,
        navigationSystem:
          element.navigationSystem?.trim().toLowerCase() === 'yes'
            ? true
            : false,
        offRoadSuspensionPackage:
          element.offRoadSuspensionPackage?.trim().toLowerCase() === 'yes'
            ? true
            : false,
        parkingAssistSystems:
          element.parkingAssistSystems?.trim().toLowerCase() === 'yes'
            ? true
            : false,
        parkingSensors:
          element.parkingSensors?.trim().toLowerCase() === 'yes' ? true : false,
        powerMirrors:
          element.powerMirrors?.trim().toLowerCase() === 'yes' ? true : false,
        premiumSoundSystem:
          element.premiumSoundSystem?.trim().toLowerCase() === 'yes'
            ? true
            : false,
        rearviewCamera:
          element.rearviewCamera?.trim().toLowerCase() === 'yes' ? true : false,
        runningBoards:
          element.runningBoards?.trim().toLowerCase() === 'yes' ? true : false,
        sunroofMoonroof:
          element.sunroofMoonroof?.trim().toLowerCase() === 'yes'
            ? true
            : false,
        towingPackage:
          element.towingPackage?.trim().toLowerCase() === 'yes' ? true : false,
        trailerBrakeController:
          element.trailerBrakeController?.trim().toLowerCase() === 'yes'
            ? true
            : false,
      }
      const licensePermitted = {
        cdlA: element.cdlA?.trim().toLowerCase() === 'yes' ? true : false,
        cdlB: element.cdlB?.trim().toLowerCase() === 'yes' ? true : false,
        cdlC: element.cdlC?.trim().toLowerCase() === 'yes' ? true : false,
        nonCdlD: element.nonCdlD?.trim().toLowerCase() === 'yes' ? true : false,
        nonCdlE: element.nonCdlE?.trim().toLowerCase() === 'yes' ? true : false,
      }
      const endorsement = {
        pEndorsement:
          element.pEndorsement?.trim().toLowerCase() === 'yes' ? true : false,
        hEndorsement:
          element.hEndorsement?.trim().toLowerCase() === 'yes' ? true : false,
        nEndorsement:
          element.nEndorsement?.trim().toLowerCase() === 'yes' ? true : false,
        tEndorsement:
          element.tEndorsement?.trim().toLowerCase() === 'yes' ? true : false,
        sEndorsement:
          element.sEndorsement?.trim().toLowerCase() === 'yes' ? true : false,
        xEndorsement:
          element.xEndorsement?.trim().toLowerCase() === 'yes' ? true : false,
      }
      const vData = {
        routrId: element.routrId,
        vehicleId: element.vehicleIdentifier,
        vehicleType: element.vehicleType,
        vehicleChassis: element.vehicleChassis,
        vehicleBody: element.vehicleBody,
        vehicleClass: element.vehicleClass,
        vehicleYear: element.vehicleYear,
        vehicleMileage: element.vehicleMileage,
        registeredState: element.registeredState,
        vehicleOwnership: element.vehicleOwnership,
        dotNumber: element.dotNumber,
        mcNumber: element.mcNumber,
        vinNumber: element.vinNumber,
        licensePlate: element.licensePlate,
        powerSource: element.powerSource,
        powerRange: element.powerRange,
        ridershipCapacity: element.ridershipCapacity,
        optionsIncluded: optionsIncluded,
        endorsement: endorsement,
        wheelchairCapacity: element.wheelchairCapacity,
        licensePermitted: licensePermitted,
        assignedDriverLate: element.assignedDriverLate,
        assignedDepot: element.assignedDepot,
        assignedParking: element.assignedParking,
        notes: element.vehicleNotes,
      }
      if (element.vehicleIdentifier) {
        const response = await createVehicle(vData)
        if (response) {
          success.push(element)
        }
        if (!response) {
          failed.push(element)
          console.error(uploadVData.message)
        }
      }

      if (v === data.length - 1) {
        return (response = {
          status: 200,
          message: 'Vehicle data uploaded successfully',
          failed,
          success,
          exists,
        })
      }
    }
  } catch (error) {
    return { status: 500, message: ':::: UploadExistingVehicleData :::', error }
  }
}

function generateYearOptions() {
  const years = []
  for (let year = 2030; year >= 1950; year--) {
    years.push(year.toString())
  }
  return years
}
