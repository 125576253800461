import apiClient from '@/services/axios'

export async function listAllDepots(query) {
  return apiClient.get('/depots', {params: query})
}

export async function getDepotById(id) {
  return apiClient.get(`/depots/${id}`)
}

export function createDepot(depot) {
  return apiClient.post('/depots', depot)
}

export async function updateDepot(id, depotData) {
  return apiClient.patch(`/depots/${id}`, depotData)
}

export async function deleteDepot(id) {
  return apiClient.delete(`/depots/${id}`)
}

export async function uploadExistingDepotData(data) {
  try {
    for (let d = 0; d < data.length; d++) {
      const element = data[d]
      const toUpload = {
        depotID: element.DepotID,
        depotName: element.DepotName,
        address: element.DepotAddress,
      }

      const depot = createDepotData(toUpload)
    }
  } catch (error) {
    return { status: 500, message: ':::: UploadExistingDepotData :::', error }
  }
}
