import apiClient from '@/services/axios'

export function createRoutingPackage(routingPackage) {
  return apiClient.post('/routing-package', routingPackage)
}

export function listAllRoutingPackage(query) {
  return apiClient.get(`/routing-package`, {
    params: query,
  })
}

export async function getRoutingPackageById(id) {
  return await apiClient.get(`/routing-package/${id}`)
}