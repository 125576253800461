export const getMenuData = [
  {
    category: true,
    title: 'Parent Portal',
    roles: 'GUARDIAN',
  },
  {
    title: 'Welcome',
    key: 'parentWelcome',
    roles: 'GUARDIAN',
    url: '/parentportal/welcomeparents',
  },
  {
    title: 'My Students',
    key: 'parentStudentList',
    roles: 'GUARDIAN',
    url: '/parentportal/students',
  },
  {
    title: 'My Profile',
    key: 'parentProfile',
    roles: 'GUARDIAN',
    url: '/parentportal/profile',
  },
  {
    title: 'Alerts',
    key: 'parentAlerts',
    roles: 'GUARDIAN',
    url: '/parentportal/alerts',
  },
  {
    title: 'Dashboard',
    key: 'welcomeScreen',
    url: '/dashboard',
    icon: 'fe fe-home',
    disabled: true,
  },
  {
    category: true,
    title: 'Tools',
    roles: ['APPSUPERADMIN', 'ADMIN', 'ACCOUNTOWNER'],
  },
  {
    title: 'Schematic',
    key: 'schematic',
    icon: 'fi fi-rs-table-list',
    url: '/schematic',
    roles: ['APPSUPERADMIN', 'ADMIN', 'ACCOUNTOWNER'],
    // disabled: true,
    // children: [
    //   {
    //     title: 'Covered Boards',
    //     key: 'Covered Boards',
    //     url: '/dashboard/timeline',
    //   },
    // ],
  },
  {
    title: 'Calendar',
    key: 'appsCalendar',
    icon: 'fi fi-ss-calendar',
    url: '/calendar',
    roles: ['APPSUPERADMIN', 'ADMIN', 'ACCOUNTOWNER', 'EMPLOYEE'],
    disabled: true,
  },
  // {
  //   category: true,
  //   title: 'Tools',
  //   roles: ['APPSUPERADMIN', 'ADMIN', 'ACCOUNTOWNER'],
  // },
  {
    roles: ['APPSUPERADMIN', 'ADMIN', 'ACCOUNTOWNER'],
    title: 'Employees',
    icon: 'fi fi-rr-user-add',
    key: 'toolsAllEmployees',
    url: '/tools/employees/all',
  },
  {
    title: 'Vehicles',
    key: 'vehicles',
    roles: ['APPSUPERADMIN', 'ADMIN', 'ACCOUNTOWNER', 'EMPLOYEE'],
    url: '/tools/vehicles/all',
  },
  {
    category: true,
    title: 'Schools',
    roles: ['APPSUPERADMIN', 'ADMIN', 'ACCOUNTOWNER'],
  },
  {
    title: 'Contracts',
    key: 'contracts',
    roles: ['APPSUPERADMIN', 'ADMIN', 'ACCOUNTOWNER', 'EMPLOYEE'],
    url: '/tools/contracts/all',
  },
  {
    title: 'Districts',
    key: 'districts',
    roles: ['APPSUPERADMIN', 'ADMIN', 'ACCOUNTOWNER', 'EMPLOYEE'],
    url: '/tools/districts/all',
  },
  {
    roles: ['APPSUPERADMIN', 'ADMIN', 'ACCOUNTOWNER'],
    title: 'Buildings',
    key: 'toolsAllBuildings',
    url: '/tools/buildings/all',
  },
  {
    title: 'Students',
    key: 'students',
    roles: ['APPSUPERADMIN', 'ADMIN', 'ACCOUNTOWNER', 'EMPLOYEE'],
    url: '/tools/students/all',
  },
  {
    category: true,
    title: 'Maps',
    roles: ['APPSUPERADMIN', 'ADMIN', 'ACCOUNTOWNER'],
  },
  {
    title: 'Routing',
    key: 'routes',
    roles: ['APPSUPERADMIN', 'ADMIN', 'ACCOUNTOWNER', 'EMPLOYEE'],
    url: '/tools/routes/all',
  },
  {
    title: 'Telematics',
    key: 'telematics',
    roles: ['APPSUPERADMIN', 'ADMIN', 'ACCOUNTOWNER', 'EMPLOYEE'],
    url: '/tools/telematics/all',
  },
]

export const getSettingMenuData = [
  {
    category: true,
    title: 'Parent Portal',
    roles: 'GUARDIAN',
  },
  {
    title: 'Welcome',
    key: 'parentWelcome',
    roles: 'GUARDIAN',
    url: '/parentportal/welcomeparents',
  },
  {
    title: 'My Students',
    key: 'parentStudentList',
    roles: 'GUARDIAN',
    url: '/parentportal/students',
  },
  {
    title: 'My Profile',
    key: 'parentProfile',
    roles: 'GUARDIAN',
    url: '/parentportal/profile',
  },
  {
    title: 'Alerts',
    key: 'parentAlerts',
    roles: 'GUARDIAN',
    url: '/parentportal/alerts',
  },
  {
    title: 'Dashboard',
    key: 'gotoDashboard',
    url: '/',
    icon: 'fe fe-arrow-left',
  },
  {
    category: true,
    title: 'Account',
    roles: ['APPSUPERADMIN', 'ADMIN', 'ACCOUNTOWNER'],
  },
  {
    title: 'Profile',
    key: 'profile',
    url: '/preferences/profile',
    disabled: true,
    roles: ['APPSUPERADMIN', 'ADMIN', 'ACCOUNTOWNER'],
  },
  {
    title: 'Settings',
    key: 'settings',
    url: '/preferences/settings',
    roles: ['APPSUPERADMIN', 'ADMIN', 'ACCOUNTOWNER', 'EMPLOYEE'],
    disabled: true,
  },
  {
    roles: ['APPSUPERADMIN', 'ADMIN', 'ACCOUNTOWNER'],
    title: 'Security',
    key: 'security',
    url: '/preferences/security',
    disabled: true,
  },
  {
    category: true,
    title: 'Administration',
    roles: ['APPSUPERADMIN', 'ADMIN', 'ACCOUNTOWNER'],
  },
  {
    title: 'Organization Profile',
    key: 'organizationProfile',
    roles: ['APPSUPERADMIN', 'ADMIN', 'ACCOUNTOWNER', 'EMPLOYEE'],
    url: '/preferences/organizationProfile',
  },
  {
    title: 'Company Profile',
    key: 'companyProfile',
    roles: ['APPSUPERADMIN', 'ADMIN', 'ACCOUNTOWNER', 'EMPLOYEE'],
    url: '/preferences/companyProfile',
  },
  {
    title: 'Manage Users',
    key: 'manageUsers',
    roles: ['APPSUPERADMIN', 'ADMIN', 'ACCOUNTOWNER', 'EMPLOYEE'],
    url: '/preferences/manageUsers',
    disabled: true,
  },
  {
    roles: ['APPSUPERADMIN', 'ADMIN', 'ACCOUNTOWNER'],
    title: 'Manage Partners',
    key: 'managePartners',
    url: '/preferences/managePartners',
    disabled: true,
  },
  {
    category: true,
    title: 'Organization',
    roles: ['APPSUPERADMIN', 'ADMIN', 'ACCOUNTOWNER'],
  },
  {
    title: 'Permissions',
    key: 'permissions',
    roles: ['APPSUPERADMIN', 'ADMIN', 'ACCOUNTOWNER', 'EMPLOYEE'],
    url: '/preferences/permissions',
    disabled: true,
  },
  {
    title: 'Employees',
    key: 'employees',
    roles: ['APPSUPERADMIN', 'ADMIN', 'ACCOUNTOWNER', 'EMPLOYEE'],
    url: '/preferences/employees',
    disabled: true,
  },
  {
    title: 'Vehicles',
    key: 'vehicles',
    roles: ['APPSUPERADMIN', 'ADMIN', 'ACCOUNTOWNER', 'EMPLOYEE'],
    url: '/preferences/vehicles',
    disabled: true,
  },
  {
    title: 'Devices',
    key: 'devices',
    roles: ['APPSUPERADMIN', 'ADMIN', 'ACCOUNTOWNER', 'EMPLOYEE'],
    url: '/preferences/devices',
  },
  {
    title: 'Facilities',
    key: 'dacilities',
    roles: ['APPSUPERADMIN', 'ADMIN', 'ACCOUNTOWNER', 'EMPLOYEE'],
    url: '/preferences/facilities',
  },
]
